import { takeLatest, call, put } from "redux-saga/effects";
import axios from "axios";
import { getRecaptchaToken } from "./RecaptchaToken";

export function* watcherInsuranceUF() {
  yield takeLatest("INSURANCE_UF_REQUEST", getInsuranceUF);
}

async function fetchInsuranceUF() {
  const recaptchaToken = await getRecaptchaToken();
  return axios({
    method: "get",
    url: `${process.env.REACT_APP_UF_SERVER}`,
    params: { },
    headers: {
      'Recaptcha-Token': recaptchaToken, 
    }
  });
}
function setWithExpiry(key, value, ttl) {
	const now = new Date()
	const item = {
		value: value,
		expiry: now.getTime() + ttl,
	}
	localStorage.setItem(key, JSON.stringify(item))
}
function getWithExpiry(key) {
	const itemStr = localStorage.getItem(key)
	if (!itemStr) {
		return null
	}
	const item = JSON.parse(itemStr)
	const now = new Date()
	if (now.getTime() > item.expiry) {
		localStorage.removeItem(key)
		return null
	}
	return item.value
}

function* getInsuranceUF() {
  try {    
    if (getWithExpiry("UF")==null)
    {
        const response = yield call(fetchInsuranceUF);    
        setWithExpiry("UF", response.data, 10000);
    }
    const data = getWithExpiry("UF");
    yield put({ type: "INSURANCE_UF_SUCCESS", data });
  } catch (error) {
    yield put({ type: "INSURANCE_UF_FAILURE", error });
  }
}