import { takeLatest, call, put } from "redux-saga/effects";
import axios from "axios";
import { getRecaptchaToken } from "./RecaptchaToken";
import usePasajerosStore from "../store/usePasajerosStore";
export function* watcherSms() {
  yield takeLatest(
    "SMS_REQUEST",
    getSMS
  );
}

async function fetchSMS({token, celular}) {
  const recaptchaToken = await getRecaptchaToken();
  const { tjw } = usePasajerosStore.getState(); 
  return axios({
    method: "POST",
    url: `${process.env.REACT_APP_URI_SERVER_LOCAL}/api/General/Sms/SolicitarSMS/`+celular,
    params: { },
    headers: {
      Authorization: "Bearer " + tjw,
      'Recaptcha-Token': recaptchaToken, 
    }
  });
}

function* getSMS(action) {
  try {
    const response = yield call(fetchSMS, action.params);
    const data = response.data;
    yield put({ type: "SMS_SUCCESS", data });
  } catch (error) {
    yield put({ type: "SMS_FAILURE", error });
  }
}
