//import '../../Styles/footer.css'
import logo from "../../imagenes/Formulario/METLIFE-logo.svg"
import logoBanco from '../../imagenes/Formulario/logo-banco-estado.png'

const Footer = () => {
  return (
    <div id="ft2">
      <div id="ftlegal2"  className="fdivtextolegal2">
        {/* <ul  className="social_footer_ul ">
        <br>
        </br>
        <br>
        </br> */}
        {/* <p className="text-center cfparrafo">
          <ApiUFFooter/>
          </p> */}
        <p className="fparrafo">
            Seguro intermediado por BancoEstado Corredores de Seguros S.A. La Compañía que asume el riesgo es MetLife Chile Seguros de Vida S.A. condiciones depositadas en la CMF bajo los siguientes códigos POL 3 2014 0335, letra A. y POL 3 2014 0335, Art. 2 Letra G. Oferta Válida para contratar hasta el 31/12/2024.<br/>
        </p>
        <div className="fdivlogo">
          <p style={{float:"left"}}>
            <img src={logoBanco} alt="Banco del Estado" className='flogo'/>
            {/* <img src={logo} className="flogo" alt="MetLife"/> */}
          </p>            
        </div>
        <div className="fdivlogo">
          <p style={{float:"right"}}>
            <img src={logo} className="flogo" alt="MetLife"/>
          </p>            
        </div>
        {/* </ul> */}
      </div>
      <input id="version" name="version" type="hidden" value={"V" + process.env.REACT_APP_VERSION} />
    </div>
  );
}
export default Footer
