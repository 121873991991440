const CIUDADES_REQUEST = "CIUDADES_REQUEST";
const CIUDADES_SUCCESS = "CIUDADES_SUCCESS";
const CIUDADES_FAILURE = "CIUDADES_FAILURE";

const initialState = {
  fetching: false,
  data: null,
  error: null,
};

const Ciudades = (state = initialState, action) => {
  switch (action.type) {
    case CIUDADES_REQUEST:
      return { ...state, fetching: true, error: null };
    case CIUDADES_SUCCESS:
      return { ...state, fetching: false, data: action.data };
    case CIUDADES_FAILURE:
      return { ...state, fetching: false, data: null, error: action.error };
    default:
      return state;
  }
};

export default Ciudades;
