import { takeLatest, call, put } from "redux-saga/effects";
import axios from "axios";
import {
  INSURANCE_TYPES_REQUEST,
  INSURANCE_TYPES_SUCCESS,
  INSURANCE_TYPES_FAILURE,
} from "../reducers/InsuranceTypes";
import { getRecaptchaToken } from "./RecaptchaToken";

async function fetchInsuranceTypes() {
  const recaptchaToken = await getRecaptchaToken();
  return axios({
    method: "get",
    url: `${process.env.REACT_APP_URI_SERVER}/api/Public/Seguros/TipoSeguros`,
    params: {
      sitio: 6,
    },
    headers: {
      'Recaptcha-Token': recaptchaToken, 
    }
  });
}

function* getInsuranceTypes() {
  try {
    const response = yield call(fetchInsuranceTypes);
    const data = response.data.map(({ id, nombreTipoSeguro }) => ({
      labelKey: id,
      value: nombreTipoSeguro,
      isSelected: false,
    }));
    yield put({ type: INSURANCE_TYPES_SUCCESS, data });
  } catch (error) {
    yield put({ type: INSURANCE_TYPES_FAILURE, error: error.message });
  }
}

export function* watcherInsuranceTypes() {
  yield takeLatest(INSURANCE_TYPES_REQUEST, getInsuranceTypes);
}