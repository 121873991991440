import React, {Component} from "react";
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import Checkout from "./components/checkout/Checkout";
import Landing from "./components/Landing";
import RespuestaTransbank from "./components/RespuestaTransbank"

export class Router extends Component{
    render(){
        return(
            <BrowserRouter>
                <Switch>
                    <Route path="/respuestatransbank" component={RespuestaTransbank} />
                    <Route path="/formulario" component={Checkout} />
                    <Route path="" component={Landing} />
                </Switch>
            </BrowserRouter>
        );
    }
}

export default Router;