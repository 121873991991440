const INSURANCE_BYTYPE_INSURANCE_REQUEST = "INSURANCE_BYTYPE_INSURANCE_REQUEST";
const INSURANCE_BYTYPE_INSURANCE_SUCCESS = "INSURANCE_BYTYPE_INSURANCE_SUCCESS";
const INSURANCE_BYTYPE_INSURANCE_FAILURE = "INSURANCE_BYTYPE_INSURANCE_FAILURE";

const initialState = {
  fetching: false,
  data: null,
  error: null,
};

const InsuranceByTypeInsurance = (state = initialState, action) => {
  switch (action.type) {
    case INSURANCE_BYTYPE_INSURANCE_REQUEST:
      return { ...state, fetching: true, error: null };
    case INSURANCE_BYTYPE_INSURANCE_SUCCESS:
      return { ...state, fetching: false, data: action.data };
    case INSURANCE_BYTYPE_INSURANCE_FAILURE:
      return { ...state, fetching: false, data: null, error: action.error };
    default:
      return state;
  }
};

export default InsuranceByTypeInsurance;
