const INSURANCE_UF_REQUEST = "INSURANCE_UF_REQUEST";
const INSURANCE_UF_SUCCESS = "INSURANCE_UF_SUCCESS";
const INSURANCE_UF_FAILURE = "INSURANCE_UF_FAILURE";

const initialState = {
  fetching: false,
  data: null,
  error: null,
};

const InsuranceUF = (state = initialState, action) => {
  switch (action.type) {
    case INSURANCE_UF_REQUEST:
      return { ...state, fetching: true, error: null };
    case INSURANCE_UF_SUCCESS:
      return { ...state, fetching: false, data: action.data };
    case INSURANCE_UF_FAILURE:
      return { ...state, fetching: false, data: null, error: action.error };
    default:
      return state;
  }
};
export default InsuranceUF;
