import '../Styles/JustoNecesitas.css'
import accidental from "../imagenes/JustoNecesitas/Accidental.svg" 
import AsistenciaMedica from "../imagenes/JustoNecesitas/AsistenciaMedica.svg" 
import Teleasistencia from "../imagenes/JustoNecesitas/Teleasistencia.svg" 
import PerdidaEquipaje from "../imagenes/JustoNecesitas/PerdidaEquipaje.svg"
import Aos from "aos"
import "aos/dist/aos.css"
import React from 'react'


const JustoNecesitas = () => {
  React.useEffect(() => {
    Aos.init({duration: 2000});
  
  }, []);

    return (
      
      <div data-aos="fade-up" id="jn">
        <div className="mt-3">
          <br />
          <h1 className="titulos">Tu seguro Incluye:</h1>
          <br />
          <section className="container">
            <div className="row row-cols-2 row-cols-md-4 mb-4 text-center">
              <div className="col">
                <div className="card mb-4 shadow-ms h-100 jncard">
                  <div className='jnimg'>
                    <img src={accidental} id="jnimg1" className="img-fluid" alt="Seguro de Salud MetLife para Fonasa e Isapre" />
                  </div>
                  <div className='jntexto'>
                    <h5 className="jntitulos">Cobertura de muerte accidental para el titular y para los asegurados adicionales, cobertura de reembolso para gastos médicos por accidente</h5>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card mb-4 shadow-ms h-100 jncard">
                  <div className='jnimg'>
                    <img src={AsistenciaMedica} id="jnimg2" className="img-fluid" alt="Contrata 100% En línea tu Seguro de Salud MetLife" />
                  </div>
                  <div className='jntexto'>
                    <h5 className="jntitulos">Asistencia médica en caso de enfermedad o accidente durante todo el viaje para todos los asegurados</h5>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card mb-4 shadow-ms h-100 jncard">
                  <div style={{marginTop:"2rem"}}>
                  <div className='jnimg'>
                    <img src={Teleasistencia} id="jnimg3" className="img-fluid" alt="Contrata la Cobertura que necesitas con tu Seguro de salud MetLife" />
                  </div>
                  <div className='jntexto'>
                    <h5 className="jntitulos">Beneficio telemedicina cuando lo necesites durante todo el viaje</h5>
                  </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card mb-4 shadow-ms h-100 jncard">
                <div style={{marginTop:"1rem"}}>
                  <div className='jnimg'>
                    <img src={PerdidaEquipaje} id="jnimg4" className="img-fluid " alt="Tu Seguro de Salud MetLife con Atención en todo Chile" />
                  </div>
                  <div className='jntexto'>
                    <h5 className="jntitulos">Beneficio ante pérdida de equipaje</h5>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </section>
          {/* <div className="text-center">
        <a className="btn btn-lg btn-primary animate__animated animate__bounce boton" href="#sr">
            Ver planes
          </a>
        </div> */}
        </div>
      </div>
    );
}

export default JustoNecesitas
