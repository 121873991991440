import { takeLatest, call, put } from "redux-saga/effects";
import axios from "axios";
import { getRecaptchaToken } from "./RecaptchaToken";
import usePasajerosStore from "../store/usePasajerosStore"

export function* watcherCiudades() {
  yield takeLatest(
    "CIUDADES_REQUEST",
    getCiudades
  );
}

export async function fetchCiudades({estadoId}) {
  const recaptchaToken = await getRecaptchaToken();
  const { tjw } = usePasajerosStore.getState(); 
  return axios({
    method: "get",
    url: `${process.env.REACT_APP_URI_SERVER_LOCAL}/api/Diccionarios/Ciudad/GetCiudadesByEstado/` + estadoId,
    params: { },
    headers: {
      Authorization: "Bearer " + tjw,
      'Recaptcha-Token': recaptchaToken, 
    }
  });
}
function* getCiudades(action) {
  try {
    const response = yield call(fetchCiudades, action.params);
    const data = response.data;
    yield put({ type: "CIUDADES_SUCCESS", data });
  } catch (error) {
    yield put({ type: "CIUDADES_FAILURE", error });
  }
}

export function* watcherEstados() {
  yield takeLatest(
    "ESTADOS_REQUEST",
    getEstados
  );
}

export async function fetchEstados({paisId}) {
  const { tjw } = usePasajerosStore.getState();
  const recaptchaToken = await getRecaptchaToken();
  return axios({
    method: "get",
    url: `${process.env.REACT_APP_URI_SERVER_LOCAL}/api/Diccionarios/Estado/GetEstadosByPais/` + paisId,
    params: { },
    headers: {
      Authorization: "Bearer " + tjw,
      'Recaptcha-Token': recaptchaToken, 
    }
  });
}

function* getEstados(action) {
  try {
    const response = yield call(fetchEstados, action.params);
    const data = response.data;
    yield put({ type: "ESTADOS_SUCCESS", data });
  } catch (error) {
    yield put({ type: "ESTADOS_FAILURE", error });
  }
}

export function* watcherPaises() {
  yield takeLatest(
    "PAISES_REQUEST",
    getPaises
  );
}

export async function fetchPaises() {
  const { tjw } = usePasajerosStore.getState(); 
  const recaptchaToken = await getRecaptchaToken();
  return axios({
    method: "get",
    url: `${process.env.REACT_APP_URI_SERVER_LOCAL}/api/Diccionarios/Pais/AllPaises`,
    params: { },
    headers: {
      Authorization: "Bearer " + tjw,
      'Recaptcha-Token': recaptchaToken, 
    }
  });
}

function* getPaises(action) {
  try {
    const response = yield call(fetchPaises, action.params);
    const data = response.data;
    yield put({ type: "PAISES_SUCCESS", data });
  } catch (error) {
    yield put({ type: "PAISES_FAILURE", error });
  }
}

export function* watcherTipoDocumentos() {
  yield takeLatest(
    "TIPODOCUMENTOS_REQUEST",
    getTipoDocumentos
  );
}

export async function fetchTipoDocumentos() {
  const { tjw } = usePasajerosStore.getState(); 
  const recaptchaToken = await getRecaptchaToken();
  return axios({
    method: "get",
    url: `${process.env.REACT_APP_URI_SERVER_LOCAL}/api/Diccionarios/TipoDocumento/AllTipoDocumentos`,
    params: { },
    headers: {
      Authorization: "Bearer " + tjw,
      'Recaptcha-Token': recaptchaToken, 
    }
  });
}

function* getTipoDocumentos(action) {
  try {
    const response = yield call(fetchTipoDocumentos, action.params);
    const data = response.data;
    yield put({ type: "TIPODOCUMENTOS_SUCCESS", data });
  } catch (error) {
    yield put({ type: "TIPODOCUMENTOS_FAILURE", error });
  }
}