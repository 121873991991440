export async function getRecaptchaToken() {
  return new Promise((resolve, reject) => {
    if (typeof window.grecaptcha !== "undefined") {
      window.grecaptcha.ready(async function () {
        try {
          const token = await window.grecaptcha.execute(
            process.env.REACT_APP_SITE_KEY,
            { action: "submit" }
          );
          resolve(token);
        } catch (error) {
          reject(error);
        }
      });
    } else {
      // Intentar recargar el script de reCAPTCHA
      const script = document.createElement("script");
      script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_SITE_KEY}`;
      script.async = true;
      script.onload = () => {
        resolve(getRecaptchaToken());
      };
      script.onerror = () => {
        reject(new Error("No se pudo recargar reCAPTCHA."));
      };
      document.body.appendChild(script);
    }
  });
}

