export const INSURANCE_TYPES_REQUEST = "INSURANCE_TYPES_REQUEST";
export const INSURANCE_TYPES_SUCCESS = "INSURANCE_TYPES_SUCCESS";
export const INSURANCE_TYPES_FAILURE = "INSURANCE_TYPES_FAILURE";

const initialState = {
  fetching: false,
  data: null,
  error: null,
};

const InsuranceTypes = (state = initialState, action) => {
  switch (action.type) {
    case INSURANCE_TYPES_REQUEST:
      return { ...state, fetching: true, error: null };
    case INSURANCE_TYPES_SUCCESS:
      return { ...state, fetching: false, data: action.data };
    case INSURANCE_TYPES_FAILURE:
      return { ...state, fetching: false, data: null, error: action.error };
    default:
      return state;
  }
};

export default InsuranceTypes;