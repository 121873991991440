export const LOAD_FORM_DATA = "LOAD_FORM_DATA";
export const UPDATE_FORM_DATA = "UPDATE_FORM_DATA";

export const loadFormData = (data) => ({
  type: LOAD_FORM_DATA,
  data,
});

export const updateFormData = (key, value) => ({
  type: UPDATE_FORM_DATA,
  key,
  value,
});

const initialState = {
  datosForm: null,
  tjw: null,
  paymentFormData: null,
  datosPasajeros: null,
};

const formReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_FORM_DATA:
      return {
        ...state,
        ...action.data,
      };
    case UPDATE_FORM_DATA:
      return {
        ...state,
        [action.key]: action.value,
      };
    default:
      return state;
  }
};

export default formReducer;
