import logo from '../imagenes/Formulario/logo-banco-estado.png'
import React, { useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import * as api from "./transbank/confirm";
import {useHistory, useLocation} from 'react-router';
import { procesarCertificado } from "../sagas/ProcesoCompra";
import { Container,AppBar, Toolbar,Paper,Grid,Alert } from "@mui/material";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import { Link } from "react-router-dom";
import Swal from 'sweetalert2';
import ReactGA from "react-ga4";
import usePasajerosStore from "../store/usePasajerosStore";

const theme = createTheme({
	palette: {
	  type: 'light',
	  primary: {
		main: '#F49500',
		contrastText: 'rgba(251,248,248,0.87)',
	  },
	  secondary: {
		main: '#f9cb7f',
		contrastText: 'rgba(70,66,66,0.87)',
	  },
	  
	  warning: {
		main: '#fdf4e2',
	  }
	},  
	typography: {
	  button: {
		textTransform: 'none'
	  }
	}
  });

const { setIdCompra } = usePasajerosStore.getState();
const showLoading = function(titulo) {
Swal.fire({
	title: titulo,
	allowEscapeKey: false,
	allowOutsideClick: false,
	showConfirmButton:false,
	showCancelButton: false,
	buttons:false,
	showLoaderOnConfirm: true,
	didOpen: () => {
	Swal.showLoading();
	}
});
};
const RespuestaTransbank = (props) => {
	const location = useLocation();
	const titleRef = useRef();
	const history = useHistory();
	const [mensaje, setMensaje] = React.useState("");
	const [huboError, setHuboError] = React.useState(false);
	const setInfoPrevious=()=>{    
		localStorage.removeItem("datosForm");		
	  };
	const borrarDatosForm = ()=>{
	setTimeout(()=>{
		localStorage.removeItem("datosForm"); 
		}, 500);
	};
	useEffect(()=>{
		ReactGA.event({ action:"Respuesta Transbank", category: "Transbank" });
	}, [location]);

	useEffect(()  =>  {
		async function consumirAPI(){
			try {
				const queryParams = new URLSearchParams(location.search);
				const token = queryParams.get("token_ws");
				showLoading("Consultando datos del pago, no cierre esta ventana");
				const confirmar = await api.confirm(token);
				Swal.close(); 				
				if(confirmar.responseCode===0){
					if(confirmar.status ==="AUTHORIZED"){ 
						 
					} else {
						setMensaje("Su pago no fue autorizado.");
					}
				} else {
					setMensaje("La pasarela de pago no ha respondido exitosamente.");
				}
				//
				showLoading("Registrando la respuesta recibida de la pasarela de pago, no cierre esta ventana");
				const respuesta = await procesarCertificado(confirmar.sessionId);
				Swal.close();
				//
				if(respuesta.status===200){
					if(respuesta.data.estado===0){
						setHuboError(false);							
						setMensaje(respuesta.data.mensaje);
						localStorage.setItem("respuestaPay", JSON.stringify(confirmar));
						setIdCompra(respuesta.data.item);
						history.push("/formulario");
					} else {
						history.replace({
							pathname: location.pathname,
							search: "",
						  });
						setHuboError(true);		
						var mensajeE = respuesta.data.mensaje;
						if(Array.isArray(respuesta.data.item)){
							for(var i =0;i<respuesta.data.item.length;i++){
								mensajeE+= (i+1)+"._"+respuesta.data.item[i];
							}
						}
						setMensaje(mensajeE);
					}
				} else {
					history.replace({
						pathname: location.pathname,
						search: "",
					  });
					setHuboError(true);
					setMensaje("El servidor no emitió una respuesta correcta");			
					borrarDatosForm();		
				}				
			} catch (error) {		
				history.replace({
					pathname: location.pathname,
					search: "",
				  });		
				borrarDatosForm();
				Swal.close(); 
				setHuboError(true);
				setMensaje("Estimado, se ha detectado un error fatal no controlado. La pasarela de pago no ha respondido exitosamente.");
				showLoading("Registrando la respuesta recibida de la pasarela de pago, no cierre esta ventana");
				//datos = {
				//	json:JSON.stringify({error:"E001: Detectado un error fatal no controlado. La pasarela de pago no ha respondido exitosamente."}),
				//	estadoproceso: estadoproceso
				//};  
				// const respuesta = await procesarPago({token:localStorage.getItem("tjw"), data: datos});
				Swal.close(); 					
			} finally {}
		}
		consumirAPI();
    }, [setMensaje])
	return (
		<ThemeProvider theme={theme}>
			<AppBar
				ref={titleRef}
				position="absolute"
				color="default"
				elevation={0}
				sx={{
				position: 'relative',
				backgroundColor:'white'
				}}>
				<Toolbar align="center">
				<p className='text-center col'>
					<img src={logo} alt="Banco del Estado" className='logo'/>
				</p>
				</Toolbar>
			</AppBar>
			<Paper elevation={0} square className="wellGray2" style={{background:"#E7E6E6"}}>
      		</Paper>
			<Container component="main" maxWidth="md" sx={{ mb: 6 }} id="padreFormulario">             
				<Paper variant="outlined" sx={{ borderTop: 5,borderTopColor: '#F49500', borderBottom:45, borderBottomColor:"#fdf4e2",  my: { xs: 0, md: 0 }, px: { xs: 1, md: 3 }, py: { xs: 1, md: 1 } }} className="franja">
					<Paper elevation={0} sx={{ borderBottom: 3, borderBottomColor: '#E7E6E6'}}>
						<Grid container spacing={0}>  
						<Grid item sm={12}>
							<Link style={{float:"right"}} className="text-right bg-gray-text no-decorator"
							onClick={setInfoPrevious}
							to="/"><KeyboardReturnIcon></KeyboardReturnIcon>  Volver</Link>
						</Grid>              
						</Grid>
					</Paper> 
					<Paper elevation={1} square className='mt-2 p-2'>  
						{ huboError ? 
						<Alert severity="error">{mensaje}</Alert>
						:
						<Alert severity="success">{mensaje}</Alert>
						}
					</Paper> 
				</Paper> 
			</Container>
			{/* <div><span>{mensaje}</span></div>		 */}
		</ThemeProvider>		
	);
};
const page = withRouter(RespuestaTransbank);
export default page;
