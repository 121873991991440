const ESTADOS_REQUEST = "ESTADOS_REQUEST";
const ESTADOS_SUCCESS = "ESTADOS_SUCCESS";
const ESTADOS_FAILURE = "ESTADOS_FAILURE";

const initialState = {
  fetching: false,
  data: null,
  error: null,
};

const Estados = (state = initialState, action) => {
  switch (action.type) {
    case ESTADOS_REQUEST:
      return { ...state, fetching: true, error: null };
    case ESTADOS_SUCCESS:
      return { ...state, fetching: false, data: action.data };
    case ESTADOS_FAILURE:
      return { ...state, fetching: false, data: null, error: action.error };
    default:
      return state;
  }
};

export default Estados;
