import '../Styles/Inicio.css'
import logo from "../imagenes/Home/logo-banco-estado.png"

const Inicio = () => {
    return (
      <>
        <header>
          <div>
            <div className="col-12 text-center">
              <img src={logo} alt="" className="logo"/>
            </div>
          </div>
        </header>
      <div id="inicio">
        <div className="container-fluid">
          <div className="row" id="parrafo">
            <div id="inparrafo" className="col-lg-5 mt-4">
              <div className="incontenedor">
                <br></br>
                <h2 id="ip1" className="animate__animated animate__fadeInLeft">
                Seguro Viaje + Protegido
                </h2>
                <h4 id="ip3" className="animate__animated animate__fadeInLeft text-left ">
                Para que viajes con tranquilidad donde sea que vayas
                </h4>                
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
    );
}

export default Inicio
