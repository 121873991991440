import React, { useState, useEffect,ReactElement, useRef, Component, useDispatch } from "react";
import Spinner from "react-bootstrap/Spinner";
import { useForm, Controller } from "react-hook-form";
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { Button,Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import $ from 'jquery';
//import DatePicker  from './DatePicker';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import { useSelector } from "react-redux";
import FormControlLabel from '@mui/material/FormControlLabel';
import { replaceAll } from "../../helpers/Tools";
import Alert from '@mui/material/Alert';
import moment from "moment";
import { render } from "@testing-library/react";
import { fetchPaises,fetchEstados,fetchCiudades,fetchTipoDocumentos } from "../../sagas/ListasCarga";
import usePasajerosStore from "../../store/usePasajerosStore";

const tipoDocumentos = [
{
    value: '1',
    label: 'RUT',
},
{
    value: '2',
    label: 'Pasaporte',
}
];
const paises = [
{
    value: '1',
    label: 'Chile',
},
{
    value: '2',
    label: 'Perú',
},{
    value: '3',
    label: 'Argentina',
}
];
const estados = [
    {
        value: '1',
        label: 'Arica',
    },
    {
        value: '2',
        label: 'Parinacota',
    },{
        value: '3',
        label: 'Tarapacá',
    }
    ];

const ciudades = [
    {
        value: '1',
        label: 'Santiago',
    },
    {
        value: '2',
        label: 'Arica',
    }
    ];
const { datosPasajeros } = usePasajerosStore.getState();
export class PasajeroFormEdit extends Component  {  
// const PasajeroForm = (props) => {        
    constructor(props) {
        super(props);
        var fecha = this.props.pasajero.fechanacimiento != null && this.props.pasajero.fechanacimiento !== "" ? moment(this.props.pasajero.fechanacimiento).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
        this.state = {
            sUsrAg: navigator.userAgent,
            error:"",
            Paises:{data: [],fetching:false},
            Estados:{data: [],fetching:false},
            Ciudades:{data: [],fetching:false},
            TipoDocumentos:{data: [],fetching:false},
            errRut:{rut:null},
            errEmail:"",
            dataForm: {
                indice: this.props.pasajero.indice,
                nombre: this.props.pasajero.nombre,
                apellido1: this.props.pasajero.apellido1,
                apellido2: this.props.pasajero.apellido2,
                identificacion: this.props.pasajero.identificacion,
                tipoDocumento: this.props.pasajero.tipoDocumento,
                email: this.props.pasajero.email,
                direccion: this.props.pasajero.direccion,
                nombresEmergencia: this.props.pasajero.nombresEmergencia,
                apellidosEmergencia: this.props.pasajero.apellidosEmergencia,
                telefonoEmergencia: this.props.pasajero.telefonoEmergencia,
                selectedValuePais: this.props.pasajero.pais,
                selectedValueEstado: this.props.pasajero.provincia,
                selectedValueCiudad: this.props.pasajero.ciudad,
                sexo:this.props.pasajero.sexo ==null || this.props.pasajero.sexo==undefined ? "": this.props.pasajero.sexo,
                fechaNacimiento: fecha,
                mismosDatos: this.props.pasajero.mismosDatos,
                mismosDatosEmail: this.props.pasajero.mismosDatosEmail
            }            
        };        
    }

    cargarTipoDocumentos = async () => {
        this.setState({
            TipoDocumentos:{data: [],fetching:true}
        });
        var respuesta = await fetchTipoDocumentos();
        if(respuesta.status===200){  
            this.setState({
                TipoDocumentos:{data: respuesta.data,fetching:false}
            });
        }else{
            this.setState({
                TipoDocumentos:{data: [],fetching:false}
            });
        }	
    }
    cargarPaises = async () => {
        this.setState({
            Paises: {data: [],fetching:true}
        });
        var respuesta = await fetchPaises();
        if(respuesta.status===200){  
            this.setState({
                Paises:{data: respuesta.data,fetching:false}
            });
        }else{
            this.setState({
                Paises: {data: [],fetching:false}
            });
        }	
    }
    cargarEstados = async () =>{
        this.setState({
            Estados:{data: [],fetching:true}
        });
        var respuesta = await fetchEstados({paisId: 140});//Solo Chile
        if(respuesta.status===200){  
            this.setState({
                Estados:{data: respuesta.data,fetching:false}
            });
        }else{
            this.setState({
                Estados: {data: [],fetching:false}
            });
        }	
    }
    cargarCiudades = async (idEstado) =>{
        this.setState({
            Ciudades: {data: [],fetching:true}
        });
        var respuesta = await fetchCiudades({estadoId: idEstado});//Solo Chile
        if(respuesta.status===200){  
            this.setState({
                Ciudades:{data: respuesta.data,fetching:false}
            });
        }else{
            this.setState({
                Ciudades: {data: [],fetching:false}
            });
        }
    }
    componentDidMount() {        
        this.cargarPaises();
        this.cargarEstados();
        this.cargarTipoDocumentos();
        if(this.props.pasajero.provincia > 0){
            this.cargarCiudades(this.props.pasajero.provincia);
        }
        this.validarTitularMismosDatosFunction();
        $('html, body').animate({
            scrollTop: $("#formularioEditar").offset().top
          }, 0);         
    }
    validarTitularMismosDatosFunction = () => {   
        var infoPreviousPasajeros = datosPasajeros;
        if(Array.isArray(infoPreviousPasajeros)){
            var titular = infoPreviousPasajeros.at(0);
            if(titular===undefined || titular===null){
                return false;
            }
            if(titular.mismosDatosEmail === "true" && this.props.pasajero.indice!==titular.indice){
                var hto = this.state.dataForm;            
                hto.email = titular.email;
                hto.confirmaremail = titular.email;
                this.setState({ 
                    validarTitularMismosDatosEmail: true,
                    dataForm: hto
                 });                
            }else{
                this.setState({
                    validarTitularMismosDatosEmail: false
                });
            }
            if(titular.mismosDatos === "true" && this.props.pasajero.indice!==titular.indice){
                this.setState({
                    validarTitularMismosDatos: true
                });
            }else{
                this.setState({
                    validarTitularMismosDatos: false
                });
            }
        }else{
            this.setState({
                validarTitularMismosDatos: false
            });
            this.setState({
                validarTitularMismosDatosEmail: false
            });
        }
    }
    componentWillUnmount() {
        
    }
    
      //FIN DE SECCION
    render(){
        const validarNoEsMismaIdentificacion = () =>{ 
            var yaExisteIdentificacion= false;     
            var infoPreviousPasajeros = usePasajerosStore.getState().datosPasajeros;
            if(Array.isArray(infoPreviousPasajeros)){
                yaExisteIdentificacion = false;
                var hto = this.state.dataForm;
                for(var i=0; i<infoPreviousPasajeros.length;i++){
                    
                    if(infoPreviousPasajeros[i].identificacion.trim()==hto.identificacion.trim()
                        && hto.indice!==infoPreviousPasajeros[i].indice){
                        yaExisteIdentificacion=true;
                    }
                }
                
            }else{
                yaExisteIdentificacion= false;
            }
            return yaExisteIdentificacion;
        }
        const handleChange = (event) => {
            let iden=event.target.value;
            var hto = this.state.dataForm;
            hto.selectedValuePais = iden;
            this.setState({ dataForm: hto });            
            //setSelectedValuePais(iden);  
        }; 
        const handleChange2 = (event) => {
            let iden=event.target.value;
            var hto = this.state.dataForm;
            hto.selectedValueEstado = iden;
            this.setState({ dataForm: hto });            
            // setSelectedValueEstado(iden);
            this.cargarCiudades(iden);
        }; 
        const handleChange3 = (event) => {
            let iden=event.target.value;
            var hto = this.state.dataForm;
            hto.selectedValueCiudad = iden;
            this.setState({ dataForm: hto });            
            // setSelectedValueCiudad(iden);
        }; 
        const handleChangeSexo = (event) => {
            let iden=event.target.value;
            var hto = this.state.dataForm;
            hto.sexo = iden;
            this.setState({ dataForm: hto });                        
        }; 
        const handleChangeConfirmarEmail = (event) => {
            let iden=event.target.value;
            let error="";
            var hto = this.state.dataForm;
            hto.confirmarEmail = iden;            
            if(hto.email != iden){
                error="El email ingresado no coincide con el del campo Email";
            }else{
                error="";
            }
            this.setState({ dataForm: hto, errEmail: error });
        }; 
        const handleChangeFechaNacimiento = (event) => {
            let iden=event.target.value;
            var hto = this.state.dataForm;
            hto.fechaNacimiento = iden;
            this.setState({ dataForm: hto });
            // setFechaNacimiento(iden);
        }; 
        const handleChangeNombre = (event) => {
            let iden=event.target.value;
            var hto = this.state.dataForm;
            hto.nombre = iden;
            this.setState({ dataForm: hto });
            // setNombre(iden);
        }; 
        const handleChangeApellido1 = (event) => {
            let iden=event.target.value;
            var hto = this.state.dataForm;
            hto.apellido1 = iden;
            this.setState({ dataForm: hto });
            // setApellido(iden);
        }; 
        const handleChangeApellido2 = (event) => {
            let iden=event.target.value;
            var hto = this.state.dataForm;
            hto.apellido2 = iden;
            this.setState({ dataForm: hto });
            // setApellido(iden);
        }; 
        const handleChangeIdentificacion = (event) => {
            let iden=event.target.value;
            var hto = this.state.dataForm;            
            if(hto.tipoDocumento == "1"){
                let labelRut = replaceAll(iden, ['.', '-'], '');
                if (
                    labelRut.length === 0 ||
                    labelRut.length === 1 ||
                    /^[0-9]+[0-9kK]{1}$/.test(labelRut)
                    ) {
                    if (!isNaN(labelRut) || labelRut.substr(-1) === 'k') {
                        if (labelRut.length >= 2)
                        labelRut = labelRut.slice(0, -1) + '-' + labelRut.slice(-1);
                        if (labelRut.length >= 6)
                        labelRut = labelRut.slice(0, -5) + '.' + labelRut.slice(-5);
                        if (labelRut.length >= 10)
                        labelRut = labelRut.slice(0, -9) + '.' + labelRut.slice(-9);
                        hto.identificacion = labelRut;
                        this.setState({ dataForm: hto });
                        // setIdentificacion(labelRut);
                    }   
                }
            }else{
                hto.identificacion = iden;
                this.setState({ dataForm: hto });
                // setIdentificacion(iden);
            }
        }; 
        const handleChangeTipoDocumento = (event) => {
            let iden=event.target.value;
            var hto = this.state.dataForm;
            hto.tipoDocumento = iden;
            this.setState({ dataForm: hto });
            // setTipoDocumento(iden);
        }; 
        const handleChangeEmail = (event) => {
            let iden=event.target.value;
            var hto = this.state.dataForm;
            hto.email = iden;
            this.setState({ dataForm: hto });
            // setEmail(iden);
        }; 
        const handleChangeDireccion = (event) => {
            let iden=event.target.value;
            var hto = this.state.dataForm;
            hto.direccion = iden;
            this.setState({ dataForm: hto });
            // setDireccion(iden);
        }; 
        const handleChangeNombresEmergencia = (event) => {
            let iden=event.target.value;  
            var hto = this.state.dataForm;
            hto.nombresEmergencia = iden;
            this.setState({ dataForm: hto });      
            // setNombresEmergencia(iden);
        }; 
        const handleChangeApellidosEmergencia = (event) => {
            let iden=event.target.value;
            var hto = this.state.dataForm;
            hto.apellidosEmergencia = iden;
            this.setState({ dataForm: hto });
            // setApellidosEmergencia(iden);
        }; 
        const handleChangeTelefonoEmergencia = (event) => {
            let iden=event.target.value;
            var hto = this.state.dataForm;
            hto.telefonoEmergencia = iden;
            this.setState({ dataForm: hto });
            // setTelefonoEmergencia(iden);
        }; 
        const handleChangeMismosDatos = (event)=>{
            var hto = this.state.dataForm;            
            hto.mismosDatos = event.target.checked+"";
            this.setState({ dataForm: hto });
        }
        const handleChangeMismosDatosEmail = (event)=>{
            var hto = this.state.dataForm;            
            hto.mismosDatosEmail = event.target.checked+"";
            this.setState({ dataForm: hto });
        }
        
        const phoneValidation = (telefono) => {
            const regex = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i;    
            return !(!telefono || regex.test(telefono) === false);
        }  
        const documentoValidation = (documento) => {
            const regex = /[&\/\\#,+()$~%.'":*?<>{}]/g;
            // var hto = this.state.dataForm;
            // if(hto.tipoDocumento!="1")
            //     return !(!documento || regex.test(documento) === true);
            // else return true;
            return true;
        }  
        //Evento de activacion del boton de agregar pasajero
        const onTrigger = (event) => {      
            event.preventDefault();       
            this.setState({error: "" });
            if(this.props.indiceMostrar===1){
                if(!phoneValidation(event.target.telefonoContacto.value)){
                    this.setState({error: "El número de teléfono ingresado no es válido" });                    
                    return;
                }  
            }
            if(this.state.errEmail!=""){
                this.setState({error: "El email ingresado en el campo Confirmar Email no coincide con el del campo Email"});
                return;
            }
            if(!documentoValidation(event.target.identificacion.value)){
                this.setState({error: "El número de documento ingresado no es válido" });                
                return;
            } 
            if (!handleValidation()) {
                this.setState({error: "El número de RUT ingresado no es válido" });
                return;
            } 
            if(validarNoEsMismaIdentificacion()){
                this.setState({error: "El número de RUT ya pertenece a otro Pasajero" });                 
                return;
            }
            event = event.target;
            if (event.nombre.value.length<2) {
                this.setState({error: "Nombre no válido" });
                return;
            }
            if (event.apellido1.value.length<2) {
                this.setState({error: "Apellido paterno no válido" });
                return; 
            }
            if (event.apellido2.value.length<1) {
                this.setState({error: "Apellido materno no válido" });
                return;
            }
            const minDate = moment().subtract(69,"year").subtract(1,"day");
            const maxDate = this.props.indiceMostrar === 1 ? moment().subtract(18,"year").add(1,"day") : moment();
            const fechaNacimiento = moment(event.fechanacimiento.value);
            if (fechaNacimiento.isBefore(minDate) || fechaNacimiento.isAfter(maxDate)) {
                this.setState({error: "Debe seleccionar una fecha entre los 18 y 69 años para poder contratar un seguro" });
                return;
            }
            var hto = this.state.dataForm;     
            var pasajero={
                indice: this.props.pasajero.indice,
                nombre: event.nombre.value,
                apellido1: event.apellido1.value, 
                apellido2: event.apellido2.value, 
                tipoDocumento: event.tipoDocumento.value,
                tipoDocumentoLabel: Array.isArray(this.state.TipoDocumentos.data) ? (this.state.TipoDocumentos.data.find(x=>x.tipodocumentoId == event.tipoDocumento.value)).etiqueta : tipoDocumentos.find(x=>x.value==event.tipoDocumento.value).label,// this.state.TipoDocumentos.data.find(x=>x.value===event.tipoDocumento.value).label,
                identificacion: event.identificacion.value, 
                direccion: event.direccion.value, 
                ciudad: event.ciudad.value, 
                ciudadLabel: Array.isArray(this.state.Ciudades.data) ? (this.state.Ciudades.data.find(x=>x.ciudadId == event.ciudad.value)).nombre : ciudades.find(x=>x.value==event.ciudad.value).label,
                pais: event.pais.value, 
                sexo: event.sexo.value,
                paisLabel: Array.isArray(this.state.Paises.data)? this.state.Paises.data.find(x=>x.paisId == event.pais.value).nombre : paises.find(x=>x.value==event.pais.value).label,
                fechanacimiento: moment(event.fechanacimiento.value).format('YYYY-MM-DD'),
                provincia: event.provincia.value, 
                provinciaLabel: Array.isArray(this.state.Estados.data)? this.state.Estados.data.find(x=>x.estadoId == event.provincia.value).nombre : estados.find(x=>x.value==event.provincia.value).label,
                email: event.email.value, 
                nombresEmergencia:  event.nombreContacto.value , 
                telefonoEmergencia: event.telefonoContacto.value, 
                apellidosEmergencia: event.apellidoContacto.value,
                mismosDatos: event.mismosDatos === undefined || event.mismosDatos==null ? false : event.mismosDatos.value,
                mismosDatosEmail: event.mismosDatosEmail === undefined || event.mismosDatosEmail==null ? false : event.mismosDatosEmail.value
            };
            resetForm(); 
            this.props.parentCallback(pasajero,"a");              
        }
        const returnNew = (event) => {      
            event.preventDefault();       
            this.setState({error: "" });
            
            event = event.target;     
            resetForm(); 
            this.props.parentCallback(this.props.pasajero,"r");
        }
          
          const resetForm = () =>{
            var iden ="";
            this.setState({
                dataForm:{
                    nombre: "",
                    apellido1: "",
                    apellido2: "",
                    identificacion: "",
                    tipoDocumento: "",
                    email: "",
                    direccion: "",
                    nombresEmergencia: "",
                    apellidosEmergencia: "",
                    telefonoEmergencia: "",
                    selectedValuePais: "",
                    selectedValueEstado: "",
                    selectedValueCiudad: "",
                    fechaNacimiento: "",
                    mismosDatos: false,
                    mismosDatosEmail: false
                }
            });
          }
          //SECCION VALIDAR RUT TOMADO DE FORM VENTAS
          //const [errRut, setErrRut] = useState({rut: null});
          const dv = T => {
            var M = 0,
              S = 1;
            for (; T; T = Math.floor(T / 10)) S = (S + (T % 10) * (9 - (M++ % 6))) % 11;
            return S ? S - 1 : 'k';
          };
          const validateRut = () => {
            var hto = this.state.dataForm;   
            const rutCompleto = replaceAll(hto.identificacion, ['.'], '');
            if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto)) return false;
        
            const tmp = rutCompleto.split('-');
            let digv = tmp[1];
            const rut = tmp[0];
            if (digv == 'K') digv = 'k';
            return dv(rut) == digv;
          };
          const validationFormRut = () => {
            const {rut} = this.state.errRut;
            var hto = this.state.dataForm;
            const err = {
              rut: hto.tipoDocumento=="1" ? !validateRut() ? 'Ingresa un Rut válido' : '' : '',
            };
            this.setState({
                errRut:err
            });
            //setErrRut(err);
            return err.rut;
          };
    
          const handleValidation = () => {
            let rut = validationFormRut();
            var hto = this.state.dataForm;
            if(hto.tipoDocumento=="1"){
                if (rut !== '') {
                return false;
                } else {
                return true;
                }
            }else{
                return true;
            }
          };
    
    return (        
        <form onSubmit={onTrigger} id="formularioEditar">     
            <Alert severity="info">
                {  this.props.indiceMostrar === 1 ? "Ingresa los datos del pasajero titular" : 
                "Ingresa los datos del pasajero #" + this.props.indiceMostrar 

                }                   
            </Alert>
            <br />   
            <Grid container spacing={3}>
                {/*<Grid item xs={12} sm={12} align="left">
                    <Typography variant="h6" gutterBottom className="text-left">
                    Datos pasajero { this.props.indiceMostrar === 1 ? "titular" : this.props.indiceMostrar }
                    </Typography>    
                </Grid>*/}                
                <Grid item xs={12} md={4}>
                    <TextField                             
                            variant="outlined"
                            label="Nombre"
                            fullWidth
                            required                            
                            value={this.state.dataForm.nombre}
                            onChange={handleChangeNombre}
                            inputProps={{ maxLength: 60 }}
                            name="nombre"  
                            id="nombre" />
                    
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField 
                            variant="outlined"
                            label="Apellido Paterno"
                            fullWidth 
                            required  
                            inputProps={{ maxLength: 60 }}
                            name="apellido1" 
                            value={this.state.dataForm.apellido1}
                            onChange={handleChangeApellido1} 
                            id="apellido1">
                            </TextField>                  
                
                </Grid> 
                <Grid item xs={12} md={4}>
                    <TextField 
                            variant="outlined"
                            label="Apellido Materno"
                            fullWidth 
                            required  
                            inputProps={{ maxLength: 60 }}
                            name="apellido2" 
                            value={this.state.dataForm.apellido2}
                            onChange={handleChangeApellido2} 
                            id="apellido2">
                            </TextField>                  
                
                </Grid>  
                <Grid item xs={12} sm={4}>   
                <TextField 
                    variant="outlined"
                    label="Sexo"
                    fullWidth                     
                    select   
                    name="sexo"       
                    onChange={handleChangeSexo}
                    value={this.state.dataForm.sexo}  
                    required              
                    id="sexo">
                        <MenuItem value={""} >
                        {"Seleccione su Sexo"}
                        </MenuItem>                                    
                        <MenuItem value={"M"}>
                            {"Masculino"}
                        </MenuItem>    
                        <MenuItem value={"F"}>
                            {"Femenino"}
                        </MenuItem>  
                        {/* <MenuItem value={"N"}>
                            {"No Definido"}
                        </MenuItem>           */}
                    </TextField>  
                </Grid>        
                <Grid item xs={12} md={4}>                    
                    <TextField                          
                            variant="outlined"
                            label="Fecha de nacimiento"
                            fullWidth
                            id="fechanacimiento"
                            required     
                            name="fechanacimiento"  
                            onChange={handleChangeFechaNacimiento}
                            value={this.state.dataForm.fechaNacimiento}
                            placeholder=""                                                                       
                            type="date"                            
                            InputLabelProps={{
                                shrink: true,
                              }}
                            InputProps={{inputProps: { min: moment().subtract(69,"year").format('YYYY-MM-DD'), max: this.props.indiceMostrar === 1 ?  moment().subtract(18,"year").format('YYYY-MM-DD') :  moment().format('YYYY-MM-DD')
                            }
                            }}
                             />                   
                </Grid>
                
                <Grid item xs={12} sm={4}>
                    <TextField 
                            variant="outlined"
                            label="Nacionalidad"
                            fullWidth                     
                            select   
                            name="pais"       
                            onChange={handleChange}
                            value={this.state.dataForm.selectedValuePais}  
                            required              
                            id="pais">
                                <MenuItem value={""} >
                                {"Seleccione su nacionalidad"}
                                </MenuItem>                                    
                                {this.state.Paises.data!==undefined && Array.isArray(this.state.Paises.data)                  
                                    ? this.state.Paises.data.map((aux, index) => (                                     
                                    <MenuItem key={index} value={aux.paisId} >
                                    {aux.nombre}
                                    </MenuItem>                                    
                                    )):
                                    paises.map((option,index) => (
                                        <MenuItem key={index} value={option.value}>
                                        {option.label}
                                        </MenuItem>
                                    ))
                                }   
                            </TextField> 
                   
                    {this.state.Paises.fetching && (
                        <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        />
                    )}
                </Grid>
                <Grid item xs={12} sm={4} className="oculto">
                    <TextField 
                            variant="outlined"
                            label="Tipo de documento"
                            fullWidth                     
                            select    
                            required   
                            InputProps={{
                                readOnly: true,
                            }}    
                            name="tipoDocumento"                         
                            value={this.state.dataForm.tipoDocumento}
                            onChange={handleChangeTipoDocumento}                                                                                       
                            id="tipoDocumento">
                                <MenuItem value={""} >
                                {"Seleccione el tipo de documento"}
                                </MenuItem>                                    
                                {this.state.TipoDocumentos.data!==undefined && Array.isArray(this.state.TipoDocumentos.data)                  
                                    ? this.state.TipoDocumentos.data.map((aux, index) => (                                     
                                    <MenuItem key={index} value={aux.tipodocumentoId} >
                                    {aux.etiqueta}
                                    </MenuItem>                                    
                                    )):
                                    tipoDocumentos.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                        </MenuItem>
                                    ))
                                }  
                                
                    </TextField>  
                    {this.state.TipoDocumentos.fetching && (
                        <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        />
                    )}              
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField 
                            variant="outlined"
                            label="Número de documento"
                            fullWidth 
                            required   
                            placeholder={Array.isArray(this.state.TipoDocumentos) && this.state.TipoDocumentos!=undefined ? 
                                this.state.TipoDocumentos.find(x=>x.tipodocumentoId === this.state.dataForm.tipoDocumento) != null ? this.state.TipoDocumentos.find(x=>x.tipodocumentoId === this.state.dataForm.tipoDocumento).etiqueta
                                 : "Tipo documento" 
                                  : "Tipo documento"}
                            inputProps={{ maxLength: 20 }}                            
                            onBlur={validationFormRut}
                            onKeyUp={validationFormRut}
                            name="identificacion"                          
                            value={this.state.dataForm.identificacion}
                            onChange={handleChangeIdentificacion}
                            helperText={this.state.errRut.rut ? this.state.errRut.rut : 'Ejemplo 9.999.999-9'}
                            id="identificacion">
                    </TextField>                    
                    {/* <span
                        className="float-left error-insured"
                        style={!errRut.rut ? {color: '#707070'} : {}}>
                        {errRut.rut ? errRut.rut : 'Ejemplo 9.999.999-9'}
                    </span>         */}
                </Grid>
                
                <Grid item xs={12} sm={4} className={this.state.validarTitularMismosDatosEmail ? "oculto" :""}>
                    <TextField
                            variant="outlined"
                            label="Email"
                            fullWidth 
                            required   
                            type="email"
                            inputProps={{ 
                                maxLength: 50 
                            }}                            
                            value={this.state.dataForm.email}
                            onChange={handleChangeEmail}
                            name="email"   
                            id="email">
                    </TextField>
                </Grid>   
                <Grid item xs={12} sm={4} className={this.state.validarTitularMismosDatosEmail ? "oculto" :""}>
                    <TextField
                            variant="outlined"
                            label="Confirmar Email"
                            fullWidth 
                            required   
                            type="email"
                            inputProps={{ 
                                maxLength: 50 
                            }}  
                            helperText={this.state.errEmail ? this.state.errEmail : ''}                          
                            value={this.state.dataForm.confirmaremail}
                            onChange={handleChangeConfirmarEmail}
                            name="confirmaremail"   
                            id="confirmaremail">
                    </TextField>
                    
                </Grid> 
                {this.props.indiceMostrar === 1 && 
                    <Grid item xs={12} sm={4}
                    className={                            
                        this.props.maxNumPasajeros=="1" ? "oculto": ""
                    }>
                        <FormGroup>
                        { this.state.dataForm.mismosDatosEmail==="true" ?
                            <FormControlLabel 
                            control={<Checkbox 
                            value={this.state.dataForm.mismosDatosEmail}
                            onChange={handleChangeMismosDatosEmail}  
                            checked
                            name="mismosDatosEmail"                                 
                            />} 
                            label="Mi email será usado para los otros pasajeros" 
                            />                       
                            :
                            <FormControlLabel 
                                    control={<Checkbox 
                                    value={this.state.dataForm.mismosDatosEmail}
                                    onChange={handleChangeMismosDatosEmail}  
                                    // checked={this.state.dataForm.mismosDatos}  
                                    name="mismosDatosEmail"                                 
                                    />} 
                                    label="Mi email será usado para los otros pasajeros" 
                                    />                       
                            }                                
                        </FormGroup>
                        {this.state.dataForm.mismosDatosEmail === "true" && 
                            <Alert severity="info">El email del Titular se copiará a los demás pasajeros al momento que haga clic en "Actualizar"</Alert>
                        }
                    </Grid>
                }  
                <Grid item xs={12} sm={4}>
                    <TextField 
                            variant="outlined"
                            label="Región"
                            fullWidth                     
                            select                                
                            required   
                            onChange={handleChange2}
                            value={this.state.dataForm.selectedValueEstado}
                            name="provincia"  
                            id="provincia">
                                <MenuItem value={""} >
                                {"Seleccione su región"}
                                </MenuItem>                 
                                {this.state.Estados.data!==undefined && Array.isArray(this.state.Estados.data)                                      
                                    ? this.state.Estados.data.map((aux, index) => (                         
                                    <MenuItem key={index} value={aux.estadoId}>
                                    {aux.nombre}
                                    </MenuItem>                                    
                                    )):
                                    estados.map((option,index) => (
                                        <MenuItem key={index} value={option.value}>
                                        {option.label}
                                        </MenuItem>
                                    ))
                                }  
                    </TextField>                
                    {this.state.Estados.fetching && (
                        <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        />
                    )}
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                            variant="outlined"
                            label="Comuna"
                            fullWidth                     
                            select    
                            required                               
                            onChange={handleChange3}
                            value={this.state.dataForm.selectedValueCiudad}
                            name="ciudad"       
                            id="ciudad">
                                <MenuItem value={""} >
                                {"Seleccione su comuna"}
                                </MenuItem>                 
                                {this.state.Ciudades.data!==undefined  && Array.isArray(this.state.Ciudades.data)                                    
                                    ? this.state.Ciudades.data.map((aux, index) => ( 
                                    <MenuItem key={index} value={aux.ciudadId}>
                                    {aux.nombre}
                                    </MenuItem>
                                    
                                    )):
                                    ciudades.map((option,index) => (
                                        <MenuItem key={index} value={option.value}>
                                        {option.label}
                                        </MenuItem>
                                    ))
                                }    
                    </TextField>
                    
               
                    {this.state.Ciudades.fetching && (
                        <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        />
                    )}
                </Grid>
            
                <Grid item xs={12}>
                    {/* {this.state.validarTitularMismosDatos ?
                    <TextField 
                            variant="outlined"
                            label="Dirección"
                            fullWidth 
                            required   
                            disabled
                            InputLabelProps={{
                                shrink: true,
                              }}
                            inputProps={{ maxLength: 50 }}
                            value={this.state.dataForm.direccion}
                            placeholder="Mz calle 321, Depto 53"
                            onChange={handleChangeDireccion}
                            name="direccion"  
                            id="direccion">
                            </TextField>                    
                    : */}
                    <TextField 
                            variant="outlined"
                            label="Dirección"
                            fullWidth 
                            required   
                            InputLabelProps={{
                                shrink: true,
                              }}
                            inputProps={{ maxLength: 50 }}
                            value={this.state.dataForm.direccion}
                            placeholder="Mz calle 321, Depto 53"
                            onChange={handleChangeDireccion}
                            name="direccion"  
                            id="direccion">
                            </TextField>                    
                    {/* } */}
                </Grid>  
                {/* {props.pasajero.indice===1 &&          */}
                
                
                <React.Fragment>
                
                    <Grid item xs={12} sm={12} align="left" className={this.state.validarTitularMismosDatos ? "oculto" :""}>
                        <Typography variant="h6" gutterBottom className="text-left">
                        Contacto de emergencia
                        </Typography>    
                        {/* <h3>Contacto de emergencia</h3> */}
                    </Grid>
                    <Grid item xs={12} sm={6} className={this.state.validarTitularMismosDatos ? "oculto" :""}>
                        {this.state.validarTitularMismosDatos ?
                        <TextField 
                                variant="outlined"
                                label="Nombre"
                                fullWidth 
                                required   
                                disabled
                                name="nombreContacto"    
                                inputProps={{ maxLength: 60 }}
                                value={this.state.dataForm.nombresEmergencia}
                                onChange={handleChangeNombresEmergencia}
                                id="nombreContacto">
                                </TextField> 
                        
                        :
                        <TextField 
                        variant="outlined"
                        label="Nombre"
                        fullWidth 
                        required   
                        name="nombreContacto"    
                        inputProps={{ maxLength: 60 }}
                        value={this.state.dataForm.nombresEmergencia}
                        onChange={handleChangeNombresEmergencia}
                        id="nombreContacto">
                        </TextField> 
                        }
                    </Grid>
                    <Grid item xs={12} sm={6} className={this.state.validarTitularMismosDatos ? "oculto" :""}>
                        {this.state.validarTitularMismosDatos ?
                        <TextField 
                                variant="outlined"
                                label="Apellido"
                                fullWidth 
                                required   
                                disabled
                                name="apellidoContacto"  
                                inputProps={{ maxLength: 60 }}
                                value={this.state.dataForm.apellidosEmergencia}
                                onChange={handleChangeApellidosEmergencia}
                                id="apellidoContacto">
                        </TextField>                    
                        :
                        <TextField 
                                variant="outlined"
                                label="Apellido"
                                fullWidth 
                                required   
                                name="apellidoContacto"  
                                inputProps={{ maxLength: 60 }}
                                value={this.state.dataForm.apellidosEmergencia}
                                onChange={handleChangeApellidosEmergencia}
                                id="apellidoContacto">
                        </TextField>                    
                        }
                    </Grid>
                    <Grid item xs={12} sm={6} className={this.state.validarTitularMismosDatos ? "oculto" :""}>
                        <label className='labelCodigoPais labelCodigoPaisForm text-center'>
                        +56
                        </label>
                        {this.state.validarTitularMismosDatos ?
                        <TextField  
                        variant="outlined"
                        label="Teléfono"
                        style={{width:"82%", borderTopLeftRadius:"0", borderBottomLeftRadius:"0"}}
                        required   
                        disabled
                        type="phone"
                        placeholder="9111111"
                        inputProps={{ minLength:9, maxLength: 9 }}
                        value={this.state.dataForm.telefonoEmergencia}
                        name="telefonoContacto"  
                        onChange={handleChangeTelefonoEmergencia}
                        id="telefonoContacto">
                        </TextField>                   
                        :
                        <TextField  
                                variant="outlined"
                                label="Teléfono"
                                style={{width:"82%", borderTopLeftRadius:"0", borderBottomLeftRadius:"0"}}
                                required   
                                type="phone"
                                placeholder="9111111"
                                inputProps={{ minLength:9, maxLength: 9 }}
                                value={this.state.dataForm.telefonoEmergencia}
                                name="telefonoContacto"  
                                onChange={handleChangeTelefonoEmergencia}
                                id="telefonoContacto">
                                </TextField>                   
                        }
                        
                    </Grid>
                
                </React.Fragment>  
                
                    
                {/* } */}
                {this.props.indiceMostrar === 1 &&
                    <Grid item xs={12} sm={12}
                    className={                            
                        this.props.maxNumPasajeros=="1" ? "oculto": ""
                    }>
                        <FormGroup>
                            { this.state.dataForm.mismosDatos==="true" ?
                            <FormControlLabel 
                            control={<Checkbox 
                            value={this.state.dataForm.mismosDatos}
                            onChange={handleChangeMismosDatos}  
                            checked
                            name="mismosDatos"                                 
                            />} 
                            label="Mis datos de contacto y dirección son los mismos para los otros pasajeros" 
                            />                       
                            :
                            <FormControlLabel 
                                    control={<Checkbox 
                                    value={this.state.dataForm.mismosDatos}
                                    onChange={handleChangeMismosDatos}  
                                    // checked={this.state.dataForm.mismosDatos}  
                                    name="mismosDatos"                                 
                                    />} 
                                    label="Mis datos de contacto y dirección son los mismos para los otros pasajeros" 
                                    />                       
                            }                      
                        </FormGroup>
                        {this.state.dataForm.mismosDatos === "true" && 
                            <Alert severity="info">Los datos de contacto y dirección del Titular se copiarán a los demás pasajeros al momento que haga clic en "Actualizar"</Alert>
                        }
                    </Grid>
                }      
                {this.state.error!=="" &&
                    <Grid item xs={12} align="center"> 
                        <Alert severity="error">{this.state.error}</Alert>
                    </Grid>
                } 
                <Grid item xs={12} align="center">                   
                    <Button variant="contained" type="submit" name="aceptar" value="a">Actualizar pasajero</Button>
                    <Button variant="outlined" style={{marginLeft:"10px"}} type="button" onClick={returnNew}>Cancelar</Button>
                </Grid>
            </Grid>
            
        </form>
    );
    }
  
}
export default PasajeroFormEdit;