import "../Styles/SeccionRenata.css";

function Condiciones(props) {
  return (
    <div id="cd">
      <section >
          <div className="container">
            <ul className="nav nav-tabs" role="tablist" style={{"borderBottom" : "none", "marginLeft": "1rem"}}>
            <div className="bccardtitulo1">
                    <h6 className="bctitulos">Condiciones de tu seguro de viajes</h6>
            </div> 
            </ul>
            <div className="tab-content">
              <div id="bccomplementario" className="container tab-pane active  ">
                <div
                  className="bcparrafo col card"
                >
                  <div className="bcparrafo3">
                    <br />
                    <ul>
                    <li>Para contratar este seguro, el asegurado titular debe ser mayor de 18 años.</li>
                    <li>La edad máxima para contratar este seguro es de 68 años y 364 días.</li>
                    <li>La edad máxima de permanencia es de 69 años y 364 días.</li>
                    <li>El asegurado titular podrá contratar sólo 1 seguro para él y sus adicionales.</li>
                    <li>La cobertura de muerte accidental en todos los planes es solo para el titular.</li>
                    <li>La asistencia en viaje es para todos los individualizados al minuto de la contratación (máximo 5 personas, incluyendo titular + adicionales).</li>
                    <li>La compañía aseguradora pagará, en caso de muerte accidental del titular de este seguro, desde UF 1.000 según el plan contratado a los herederos legales del mismo.</li>
                    <li>La cobertura de reembolso por gastos médicos accidental es solo para los asegurados adicionales de este seguro que incurran por consecuencia de un accidente en gastos médicos y farmacéuticos. El capital asegurado será de UF 15 (por cada adicional que contrate este seguro).</li>
                    <li>Se entiende por accidente todo suceso imprevisto, involuntario, repentino y fortuito, causado por medios externos y de modo violento que afecte al Asegurado, ocasionándole una o más lesiones (ya sea visibles o internas), incluye ahogamiento, asfixia, torceduras, desgarramientos producidos por esfuerzos repentinos, estados septicémicos e infecciones que sean la consecuencia de heridas externas e involuntarias.</li>
                    <li>No se cubrirá la muerte accidental o reembolso de gastos médicos por accidente a consecuencia de:
                        <ul className="bclu">
                        <li>Efectos de guerra, declarada o no declarada, invasión, acción de un enemigo extranjero, hostilidades u operaciones bélicas, ya sea con o sin declaración de guerra, así como tampoco ningún ejercicio o práctica de guerra.</li>
                        <li>Suicidio, intento de suicidio, o heridas auto inferidas, ya sea que el asegurado haya estado en su pleno juicio o enajenado mentalmente.</li>
                        <li>Prestación de servicios del asegurado en las Fuerzas Armadas o funciones policiales de cualquier tipo. Para todos los efectos de esta póliza las funciones de policía incluyen además las funciones de policía civil y gendarmería.</li>
                        <li>Intoxicación o encontrarse el asegurado bajo los efectos de cualquier narcótico o droga a menos que hubiese sido administrado por prescripción médica, y la conducción de cualquier vehículo por parte del asegurado, encontrándose éste en estado de ebriedad, conforme a los límites establecidos en la normativa vigente a la fecha del siniestro. Dichos estados deberán acreditarse mediante la documentación expedida por los organismos correspondientes.</li>
                        <li>Negligencia o imprudencia o culpa grave del asegurado.</li>
                        <li>Movimientos sísmicos desde el grado 8 inclusive en la escala de Mercalli, determinado por el Servicio Sismológico del Departamento de Centro Sismológico Nacional o del servicio que en el futuro lo reemplace.</li>
                        <li>Viaje o vuelo en vehículo aéreo en un transporte no sujeto a itinerario, ni operado por una empresa de transporte aéreo comercial sobre una ruta establecida.</li>
                        <li>Fractura de Huesos producida como consecuencia directa e inmediata de osteoporosis.</li>
                        </ul>
                    </li>
                    </ul>                    
                  </div>
                </div>
              </div>
              <div id="bccatastrofico" className="container tab-pane fade">
                <div
                  className="bcparrafo col card"
                >
                  <p className="bcparrafo3">
                    <br />
                    Debes tener una afiliación vigente de Isapre o Fonasa.
                    <br />
                    <br />
                    Puedes contratar como titular si tienes, al menos, 18 años y eres menor de 70 años.
                    <br />
                    <br />
                    Puedes incorporar como carga a tu pareja (cónyuge/conviviente civil) y a 
                    tus hijos desde los 14 días de vida y hasta los 23 años.
                    <br />
                    <br />
                    No puedes asegurar a tus padres o abuelos como cargas.
                    <br />
                    <br />
                    Tanto tú como tu cónyuge (en caso de ser carga) podrán permanecer asegurados 
                    en tanto menores de 100 años. Los hijos que sean carga podrán en tanto menores de 24 años.
                    <br />
                    <br />
                    No cubre preexistencias.
                    <br />
                    <br />
                    Debes completar una Declaración Personal de Salud para poder avanzar 
                    en la contratación. Aplican exclusiones.
                    <br />
                    <br />
                    Los gastos médicos derivados de un mismo diagnóstico se considerarán parte de un mismo evento durante 3 años 
                    en los que aplicará una sola vez el deducible y que estarán afectos al monto máximo de reembolso. 
                    Transcurrido el plazo, se considerará como un nuevo evento reinstalándose el deducible y el Monto Máximo de Reembolso.
                    <br />
                    <br />
                    Incluye cobertura por ambulancia terrestre con tope de UF 10 por evento, 
                    sin límite de kilómetros y sin tope de eventos, si el asegurado es hospitalizado.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
    </div>
  );
}
export default Condiciones;
