const PAISES_REQUEST = "PAISES_REQUEST";
const PAISES_SUCCESS = "PAISES_SUCCESS";
const PAISES_FAILURE = "PAISES_FAILURE";

const initialState = {
  fetching: false,
  data: null,
  error: null,
};

const Paises = (state = initialState, action) => {
  switch (action.type) {
    case PAISES_REQUEST:
      return { ...state, fetching: true, error: null };
    case PAISES_SUCCESS:
      return { ...state, fetching: false, data: action.data };
    case PAISES_FAILURE:
      return { ...state, fetching: false, data: null, error: action.error };
    default:
      return state;
  }
};

export default Paises;
