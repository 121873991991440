import moment from 'moment'

export const dateNow = moment().format('DD/MM/YYYY');
export const yearNow = moment().format('YYYY');

export const replaceAll = (texto, listViejo, nuevo) => {
  listViejo.map(viejo => {
    texto = texto.split(viejo).join(nuevo);
  });
  return texto;
};

// Funciones para validar el rut
export const validaRutDv = T => {
  var M = 0,
    S = 1;
  for (; T; T = Math.floor(T / 10)) S = (S + (T % 10) * (9 - (M++ % 6))) % 11;
  return S ? S - 1 : 'k';
};

export const validaRut = rutCompleto => {
  rutCompleto = rutCompleto.replace('‐', '-');
  if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto)) return false;
  var tmp = rutCompleto.split('-');
  var digv = tmp[1];
  var rut = tmp[0];
  if (digv == 'K') digv = 'k';

  return validaRutDv(rut) == digv;
};

export const validateEmail = (email) => {
  if(email.trim() === '') return false

  // const validEmailRegex = RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);
  const validEmailRegex = RegExp(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/);
  return validEmailRegex.test(email)

}
export const validateName = (name) => {
  if(name.trim() === '') return false
  const validNameRegex = RegExp(/^[a-zA-ZÀ-ÿ\s]{1,50}$/);
  return validNameRegex.test(name)

}





export const getErrorMessage = code => {
  const errors = {
    '000':
      ' Momentáneamente nuestra página tuvo un error. Gracias por tu comprensión.',
    111:
      ' Momentáneamente nuestra página tuvo un error, presione el boton nuevamente. Gracias por tu comprensión.',
    '001': 'Verifique su RUT',
    '002': 'Verifique el monto',
    '003': 'El RUT utilizado ha llegado al número máximo de compras de seguro',
    '004': 'Código de verificación es inválido',
  };
  return errors[code || '000'];
};

export const getCommunes = () => {
  return [
    'Comuna,Región',
    'Colchane, Región de Tarapacá',
    'Camiña, Región de Tarapacá',
    'Alto Hospicio, Región de Tarapacá',
    'Iquique, Región de Tarapacá',
    'Pozo Almonte, Región de Tarapacá',
    'Pica, Región de Tarapacá',
    'Huara, Región de Tarapacá',
    'Calama, Región de Antofagasta',
    'Antofagasta, Región de Antofagasta',
    'Tocopilla, Región de Antofagasta',
    'Taltal, Región de Antofagasta',
    'Sierra Gorda, Región de Antofagasta',
    'San Pedro Atacama, Región de Antofagasta',
    'Ollague, Región de Antofagasta',
    'Mejillones, Región de Antofagasta',
    'María Elena, Región de Antofagasta',
    'Alto Del Carmen, Región de Atacama',
    'Huasco, Región de Atacama',
    'Copiapó, Región de Atacama',
    'Chañaral, Región de Atacama',
    'Vallenar, Región de Atacama',
    'Freirina, Región de Atacama',
    'Tierra Amarilla, Región de Atacama',
    'Caldera, Región de Atacama',
    'Diego De Almagro, Región de Atacama',
    'Punitaqui, Región de Coquimbo',
    'Río Hurtado, Región de Coquimbo',
    'Coquimbo, Región de Coquimbo',
    'Combarbala, Región de Coquimbo',
    'Canela, Región de Coquimbo',
    'Vicuña, Región de Coquimbo',
    'Paihuano, Región de Coquimbo',
    'Monte Patria, Región de Coquimbo',
    'Salamanca, Región de Coquimbo',
    'Los Vilos, Región de Coquimbo',
    'La Serena, Región de Coquimbo',
    'La Higuera, Región de Coquimbo',
    'Andacollo, Región de Coquimbo',
    'Illapel, Región de Coquimbo',
    'Ovalle, Región de Coquimbo',
    'Algarrobo, Región de Valparaíso',
    'Cabildo, Región de Valparaíso',
    'Calle Larga, Región de Valparaíso',
    'Cartagena, Región de Valparaíso',
    'Casablanca, Región de Valparaíso',
    'Catemu, Región de Valparaíso',
    'Concón, Región de Valparaíso',
    'El Quisco, Región de Valparaíso',
    'El Tabo, Región de Valparaíso',
    'Hijuelas, Región de Valparaíso',
    'Isla De Pascua, Región de Valparaíso',
    'Juan Fernández, Región de Valparaíso',
    'La Calera, Región de Valparaíso',
    'La Cruz, Región de Valparaíso',
    'La Ligua, Región de Valparaíso',
    'Limache, Región de Valparaíso',
    'Llay Llay, Región de Valparaíso',
    'Los Andes, Región de Valparaíso',
    'Nogales, Región de Valparaíso',
    'Olmué, Región de Valparaíso',
    'Panquehue, Región de Valparaíso',
    'Papudo, Región de Valparaíso',
    'Petorca, Región de Valparaíso',
    'Puchuncaví, Región de Valparaíso',
    'Putaendo, Región de Valparaíso',
    'Quillota, Región de Valparaíso',
    'Quilpué, Región de Valparaíso',
    'Quintero, Región de Valparaíso',
    'Rinconada, Región de Valparaíso',
    'San Antonio, Región de Valparaíso',
    'San Esteban, Región de Valparaíso',
    'San Felipe, Región de Valparaíso',
    'Santa María, Región de Valparaíso',
    'Santo Domingo, Región de Valparaíso',
    'Valparaíso, Región de Valparaíso',
    'Villa Alemana, Región de Valparaíso',
    'Viña Del Mar, Región de Valparaíso',
    'Zapallar, Región de Valparaíso',
    "Chépica, Región del Libertador General Bernardo O'Higgins",
    "Chimbarongo, Región del Libertador General Bernardo O'Higgins",
    "Codegua, Región del Libertador General Bernardo O'Higgins",
    "Coinco, Región del Libertador General Bernardo O'Higgins",
    "Coltauco, Región del Libertador General Bernardo O'Higgins",
    "Doñihue, Región del Libertador General Bernardo O'Higgins",
    "Graneros, Región del Libertador General Bernardo O'Higgins",
    "La Estrella, Región del Libertador General Bernardo O'Higgins",
    "Las Cabras, Región del Libertador General Bernardo O'Higgins",
    "Litueche, Región del Libertador General Bernardo O'Higgins",
    "Lolol, Región del Libertador General Bernardo O'Higgins",
    "Machalí, Región del Libertador General Bernardo O'Higgins",
    "Malloa, Región del Libertador General Bernardo O'Higgins",
    "Marchigue, Región del Libertador General Bernardo O'Higgins",
    "Nancagua, Región del Libertador General Bernardo O'Higgins",
    "Navidad, Región del Libertador General Bernardo O'Higgins",
    "Olivar, Región del Libertador General Bernardo O'Higgins",
    "Palmilla, Región del Libertador General Bernardo O'Higgins",
    "Paredones, Región del Libertador General Bernardo O'Higgins",
    "Peralillo, Región del Libertador General Bernardo O'Higgins",
    "Peumo, Región del Libertador General Bernardo O'Higgins",
    "Pichidegua, Región del Libertador General Bernardo O'Higgins",
    "Pichilemu, Región del Libertador General Bernardo O'Higgins",
    "Placilla, Región del Libertador General Bernardo O'Higgins",
    "Pumanque, Región del Libertador General Bernardo O'Higgins",
    "Quinta De Tilcoco, Región del Libertador General Bernardo O'Higgins",
    "Rancagua, Región del Libertador General Bernardo O'Higgins",
    "Rengo, Región del Libertador General Bernardo O'Higgins",
    "Requinoa, Región del Libertador General Bernardo O'Higgins",
    "San Fernando, Región del Libertador General Bernardo O'Higgins",
    "San Vicente, Región del Libertador General Bernardo O'Higgins",
    "Santa Cruz, Región del Libertador General Bernardo O'Higgins",
    "Sn. Fco. De Mostazal, Región del Libertador General Bernardo O'Higgins",
    'Cauquenes, Región del Maule',
    'Chanco, Región del Maule',
    'Colbún, Región del Maule',
    'Constitución, Región del Maule',
    'Curepto, Región del Maule',
    'Curicó, Región del Maule',
    'Empedrado, Región del Maule',
    'Hualañe, Región del Maule',
    'Licantén, Región del Maule',
    'Linares, Región del Maule',
    'Longaví, Región del Maule',
    'Maule, Región del Maule',
    'Molina, Región del Maule',
    'Parral, Región del Maule',
    'Pelarco, Región del Maule',
    'Pelluhue, Región del Maule',
    'Pencahue, Región del Maule',
    'Rauco, Región del Maule',
    'Retiro, Región del Maule',
    'Río Claro, Región del Maule',
    'Romeral, Región del Maule',
    'Sagrada Familia, Región del Maule',
    'San Clemente, Región del Maule',
    'San Javier, Región del Maule',
    'San Rafael, Región del Maule',
    'Talca, Región del Maule',
    'Teno, Región del Maule',
    'Vichuquén, Región del Maule',
    'Villa Alegre, Región del Maule',
    'Yerbas Buenas, Región del Maule',
    'Alto Bíobío, Región del Bío-Bío',
    'Antuco, Región del Bío-Bío',
    'Arauco, Región del Bío-Bío',
    'Bulnes, Región del Bío-Bío',
    'Cabrero, Región del Bío-Bío',
    'Cañete, Región del Bío-Bío',
    'Chiguayante, Región del Bío-Bío',
    'Chillán, Región del Bío-Bío',
    'Chillán Viejo, Región del Bío-Bío',
    'Cobquecura, Región del Bío-Bío',
    'Coelemu, Región del Bío-Bío',
    'Coihueco, Región del Bío-Bío',
    'Concepción, Región del Bío-Bío',
    'Contulmo, Región del Bío-Bío',
    'Coronel, Región del Bío-Bío',
    'Curanilahue, Región del Bío-Bío',
    'El Carmen, Región del Bío-Bío',
    'Florida, Región del Bío-Bío',
    'Hualpén, Región del Bío-Bío',
    'Hualqui, Región del Bío-Bío',
    'Laja, Región del Bío-Bío',
    'Lebu, Región del Bío-Bío',
    'Los Álamos, Región del Bío-Bío',
    'Los Ángeles, Región del Bío-Bío',
    'Lota, Región del Bío-Bío',
    'Mulchén, Región del Bío-Bío',
    'Nacimiento, Región del Bío-Bío',
    'Negrete, Región del Bío-Bío',
    'Ninhue, Región del Bío-Bío',
    'Ñiquén, Región del Bío-Bío',
    'Pemuco, Región del Bío-Bío',
    'Penco, Región del Bío-Bío',
    'Pinto, Región del Bío-Bío',
    'Portezuelo, Región del Bío-Bío',
    'Quilaco, Región del Bío-Bío',
    'Quilleco, Región del Bío-Bío',
    'Quillón, Región del Bío-Bío',
    'Quirihue, Región del Bío-Bío',
    'Ránquil, Región del Bío-Bío',
    'San Carlos, Región del Bío-Bío',
    'San Fabián, Región del Bío-Bío',
    'San Ignacio, Región del Bío-Bío',
    'San Nicolás, Región del Bío-Bío',
    'San Pedro De La Paz, Región del Bío-Bío',
    'San Rosendo, Región del Bío-Bío',
    'Santa Bárbara, Región del Bío-Bío',
    'Santa Juana, Región del Bío-Bío',
    'Talcahuano, Región del Bío-Bío',
    'Tirúa, Región del Bío-Bío',
    'Tomé, Región del Bío-Bío',
    'Trehuaco, Región del Bío-Bío',
    'Tucapel, Región del Bío-Bío',
    'Yumbel, Región del Bío-Bío',
    'Yungay, Región del Bío-Bío',
    'Angol, Región de la Araucanía',
    'Carahue, Región de la Araucanía',
    'Cholchol, Región de la Araucanía',
    'Collipulli, Región de la Araucanía',
    'Cunco, Región de la Araucanía',
    'Curacautín, Región de la Araucanía',
    'Curarrehue, Región de la Araucanía',
    'Ercilla, Región de la Araucanía',
    'Freire, Región de la Araucanía',
    'Galvarino, Región de la Araucanía',
    'Gorbea, Región de la Araucanía',
    'Lautaro, Región de la Araucanía',
    'Loncoche, Región de la Araucanía',
    'Lonquimay, Región de la Araucanía',
    'Los Sauces, Región de la Araucanía',
    'Lumaco, Región de la Araucanía',
    'Melipeuco, Región de la Araucanía',
    'Nueva Imperial, Región de la Araucanía',
    'Padre Las Casas, Región de la Araucanía',
    'Perquenco, Región de la Araucanía',
    'Pitrufquén, Región de la Araucanía',
    'Pucón, Región de la Araucanía',
    'Purén, Región de la Araucanía',
    'Renaico, Región de la Araucanía',
    'Saavedra, Región de la Araucanía',
    'Temuco, Región de la Araucanía',
    'Teodoro Schmidt, Región de la Araucanía',
    'Toltén, Región de la Araucanía',
    'Traiguén, Región de la Araucanía',
    'Victoria, Región de la Araucanía',
    'Vilcún, Región de la Araucanía',
    'Villarrica, Región de la Araucanía',
    'Ancud, Región de Los Lagos',
    'Calbuco, Región de Los Lagos',
    'Castro, Región de Los Lagos',
    'Chaitén, Región de Los Lagos',
    'Chonchi, Región de Los Lagos',
    'Cochamó, Región de Los Lagos',
    'Curaco De Vélez, Región de Los Lagos',
    'Dalcahue, Región de Los Lagos',
    'Fresia, Región de Los Lagos',
    'Frutillar, Región de Los Lagos',
    'Futaleufú, Región de Los Lagos',
    'Hualaihué, Región de Los Lagos',
    'Llanquihue, Región de Los Lagos',
    'Los Muermos, Región de Los Lagos',
    'Maullín, Región de Los Lagos',
    'Osorno, Región de Los Lagos',
    'Palena, Región de Los Lagos',
    'Puerto Montt, Región de Los Lagos',
    'Puerto Octay, Región de Los Lagos',
    'Puerto Varas, Región de Los Lagos',
    'Puqueldón, Región de Los Lagos',
    'Purranque, Región de Los Lagos',
    'Puyehue, Región de Los Lagos',
    'Queilén, Región de Los Lagos',
    'Quellón, Región de Los Lagos',
    'Quemchi, Región de Los Lagos',
    'Quinchao, Región de Los Lagos',
    'Río Negro, Región de Los Lagos',
    'San Juan De La Costa, Región de Los Lagos',
    'San Pablo, Región de Los Lagos',
    'Aysén, Región de Aysén del General Carlos Ibáñez del Campo',
    'Chile Chico, Región de Aysén del General Carlos Ibáñez del Campo',
    'Cisnes, Región de Aysén del General Carlos Ibáñez del Campo',
    'Cochrane, Región de Aysén del General Carlos Ibáñez del Campo',
    'Coyhaique, Región de Aysén del General Carlos Ibáñez del Campo',
    'Guaitecas, Región de Aysén del General Carlos Ibáñez del Campo',
    'Lago Verde, Región de Aysén del General Carlos Ibáñez del Campo',
    'Ohiggins, Región de Aysén del General Carlos Ibáñez del Campo',
    'Río Ibañez, Región de Aysén del General Carlos Ibáñez del Campo',
    'Tortel, Región de Aysén del General Carlos Ibáñez del Campo',
    'Cabo De Hornos, Región de Magallanes y la Antártica Chilena',
    'Laguna Blanca, Región de Magallanes y la Antártica Chilena',
    'Natales, Región de Magallanes y la Antártica Chilena',
    'Porvenir, Región de Magallanes y la Antártica Chilena',
    'Primavera, Región de Magallanes y la Antártica Chilena',
    'Punta Arenas, Región de Magallanes y la Antártica Chilena',
    'Río Verde, Región de Magallanes y la Antártica Chilena',
    'San Gregorio, Región de Magallanes y la Antártica Chilena',
    'Timaukel, Región de Magallanes y la Antártica Chilena',
    'Torres Del Paine, Región de Magallanes y la Antártica Chilena',
    'Alhué, Región Metropolitana',
    'Buin, Región Metropolitana',
    'Calera De Tango, Región Metropolitana',
    'Curacaví, Región Metropolitana',
    'El Monte, Región Metropolitana',
    'Isla De Maipo, Región Metropolitana',
    'María Pinto, Región Metropolitana',
    'Melipilla, Región Metropolitana',
    'Paine, Región Metropolitana',
    'Peñaflor, Región Metropolitana',
    'San José Maipo, Región Metropolitana',
    'Padre Hurtado, Región Metropolitana',
    'Pedro Aguirre Cerda, Región Metropolitana',
    'Peñalolén, Región Metropolitana',
    'Pirque, Región Metropolitana',
    'Providencia, Región Metropolitana',
    'Pudahuel, Región Metropolitana',
    'Puente Alto, Región Metropolitana',
    'Quilicura, Región Metropolitana',
    'Quinta Normal, Región Metropolitana',
    'Recoleta, Región Metropolitana',
    'Renca, Región Metropolitana',
    'San Bernardo, Región Metropolitana',
    'San Joaquín, Región Metropolitana',
    'San Miguel, Región Metropolitana',
    'San Pedro, Región Metropolitana',
    'San Ramón, Región Metropolitana',
    'Santiago, Región Metropolitana',
    'Vitacura, Región Metropolitana',
    'Cerrillos, Región Metropolitana',
    'Cerro Navia, Región Metropolitana',
    'Colina, Región Metropolitana',
    'Conchalí, Región Metropolitana',
    'El Bosque, Región Metropolitana',
    'Estación Central, Región Metropolitana',
    'Huechuraba, Región Metropolitana',
    'Independencia, Región Metropolitana',
    'La Cisterna, Región Metropolitana',
    'La Florida, Región Metropolitana',
    'La Granja, Región Metropolitana',
    'La Pintana, Región Metropolitana',
    'La Reina, Región Metropolitana',
    'Lampa, Región Metropolitana',
    'Las Condes, Región Metropolitana',
    'Lo Barnechea, Región Metropolitana',
    'Lo Espejo, Región Metropolitana',
    'Lo Prado, Región Metropolitana',
    'Macul, Región Metropolitana',
    'Maipú, Región Metropolitana',
    'Nuñoa, Región Metropolitana',
    'Talagante, Región Metropolitana',
    'Tíl Tíl, Región Metropolitana',
    'Corral, Región de Los Ríos',
    'Futrono, Región de Los Ríos',
    'La Unión, Región de Los Ríos',
    'Lago Ranco, Región de Los Ríos',
    'Lanco, Región de Los Ríos',
    'Los Lagos, Región de Los Ríos',
    'Máfil, Región de Los Ríos',
    'Mariquina, Región de Los Ríos',
    'Paillaco, Región de Los Ríos',
    'Panguipulli, Región de Los Ríos',
    'Río Bueno, Región de Los Ríos',
    'Valdivia, Región de Los Ríos',
    'Arica, Región de Arica y Parinacota',
    'Camarones, Región de Arica y Parinacota',
    'General Lagos, Región de Arica y Parinacota',
    'Putre, Región de Arica y Parinacota',
  ];
};

export const getRegion = comuna => {
  const regiones = {
    Colchane: 'Región de Tarapacá',
    Camiña: 'Región de Tarapacá',
    'Alto Hospicio': 'Región de Tarapacá',
    Iquique: 'Región de Tarapacá',
    'Pozo Almonte': 'Región de Tarapacá',
    Pica: 'Región de Tarapacá',
    Huara: 'Región de Tarapacá',
    Calama: 'Región de Antofagasta',
    Antofagasta: 'Región de Antofagasta',
    Tocopilla: 'Región de Antofagasta',
    Taltal: 'Región de Antofagasta',
    'Sierra Gorda': 'Región de Antofagasta',
    'San Pedro Atacama': 'Región de Antofagasta',
    Ollague: 'Región de Antofagasta',
    Mejillones: 'Región de Antofagasta',
    'María Elena': 'Región de Antofagasta',
    'Alto Del Carmen': 'Región de Atacama',
    Huasco: 'Región de Atacama',
    Copiapó: 'Región de Atacama',
    Chañaral: 'Región de Atacama',
    Vallenar: 'Región de Atacama',
    Freirina: 'Región de Atacama',
    'Tierra Amarilla': 'Región de Atacama',
    Caldera: 'Región de Atacama',
    'Diego De Almagro': 'Región de Atacama',
    Punitaqui: 'Región de Coquimbo',
    'Río Hurtado': 'Región de Coquimbo',
    Coquimbo: 'Región de Coquimbo',
    Combarbala: 'Región de Coquimbo',
    Canela: 'Región de Coquimbo',
    Vicuña: 'Región de Coquimbo',
    Paihuano: 'Región de Coquimbo',
    'Monte Patria': 'Región de Coquimbo',
    Salamanca: 'Región de Coquimbo',
    'Los Vilos': 'Región de Coquimbo',
    'La Serena': 'Región de Coquimbo',
    'La Higuera': 'Región de Coquimbo',
    Andacollo: 'Región de Coquimbo',
    Illapel: 'Región de Coquimbo',
    Ovalle: 'Región de Coquimbo',
    Algarrobo: 'Región de Valparaíso',
    Cabildo: 'Región de Valparaíso',
    'Calle Larga': 'Región de Valparaíso',
    Cartagena: 'Región de Valparaíso',
    Casablanca: 'Región de Valparaíso',
    Catemu: 'Región de Valparaíso',
    Concón: 'Región de Valparaíso',
    'El Quisco': 'Región de Valparaíso',
    'El Tabo': 'Región de Valparaíso',
    Hijuelas: 'Región de Valparaíso',
    'Isla De Pascua': 'Región de Valparaíso',
    'Juan Fernández': 'Región de Valparaíso',
    'La Calera': 'Región de Valparaíso',
    'La Cruz': 'Región de Valparaíso',
    'La Ligua': 'Región de Valparaíso',
    Limache: 'Región de Valparaíso',
    'Llay Llay': 'Región de Valparaíso',
    'Los Andes': 'Región de Valparaíso',
    Nogales: 'Región de Valparaíso',
    Olmué: 'Región de Valparaíso',
    Panquehue: 'Región de Valparaíso',
    Papudo: 'Región de Valparaíso',
    Petorca: 'Región de Valparaíso',
    Puchuncaví: 'Región de Valparaíso',
    Putaendo: 'Región de Valparaíso',
    Quillota: 'Región de Valparaíso',
    Quilpué: 'Región de Valparaíso',
    Quintero: 'Región de Valparaíso',
    Rinconada: 'Región de Valparaíso',
    'San Antonio': 'Región de Valparaíso',
    'San Esteban': 'Región de Valparaíso',
    'San Felipe': 'Región de Valparaíso',
    'Santa María': 'Región de Valparaíso',
    'Santo Domingo': 'Región de Valparaíso',
    Valparaíso: 'Región de Valparaíso',
    'Villa Alemana': 'Región de Valparaíso',
    'Viña Del Mar': 'Región de Valparaíso',
    Zapallar: 'Región de Valparaíso',
    Chépica: "Región del Libertador General Bernardo O'Higgins",
    Chimbarongo: "Región del Libertador General Bernardo O'Higgins",
    Codegua: "Región del Libertador General Bernardo O'Higgins",
    Coinco: "Región del Libertador General Bernardo O'Higgins",
    Coltauco: "Región del Libertador General Bernardo O'Higgins",
    Doñihue: "Región del Libertador General Bernardo O'Higgins",
    Graneros: "Región del Libertador General Bernardo O'Higgins",
    'La Estrella': "Región del Libertador General Bernardo O'Higgins",
    'Las Cabras': "Región del Libertador General Bernardo O'Higgins",
    Litueche: "Región del Libertador General Bernardo O'Higgins",
    Lolol: "Región del Libertador General Bernardo O'Higgins",
    Machalí: "Región del Libertador General Bernardo O'Higgins",
    Malloa: "Región del Libertador General Bernardo O'Higgins",
    Marchigue: "Región del Libertador General Bernardo O'Higgins",
    Nancagua: "Región del Libertador General Bernardo O'Higgins",
    Navidad: "Región del Libertador General Bernardo O'Higgins",
    Olivar: "Región del Libertador General Bernardo O'Higgins",
    Palmilla: "Región del Libertador General Bernardo O'Higgins",
    Paredones: "Región del Libertador General Bernardo O'Higgins",
    Peralillo: "Región del Libertador General Bernardo O'Higgins",
    Peumo: "Región del Libertador General Bernardo O'Higgins",
    Pichidegua: "Región del Libertador General Bernardo O'Higgins",
    Pichilemu: "Región del Libertador General Bernardo O'Higgins",
    Placilla: "Región del Libertador General Bernardo O'Higgins",
    Pumanque: "Región del Libertador General Bernardo O'Higgins",
    'Quinta De Tilcoco': "Región del Libertador General Bernardo O'Higgins",
    Rancagua: "Región del Libertador General Bernardo O'Higgins",
    Rengo: "Región del Libertador General Bernardo O'Higgins",
    Requinoa: "Región del Libertador General Bernardo O'Higgins",
    'San Fernando': "Región del Libertador General Bernardo O'Higgins",
    'San Vicente': "Región del Libertador General Bernardo O'Higgins",
    'Santa Cruz': "Región del Libertador General Bernardo O'Higgins",
    'Sn. Fco. De Mostazal': "Región del Libertador General Bernardo O'Higgins",
    Cauquenes: 'Región del Maule',
    Chanco: 'Región del Maule',
    Colbún: 'Región del Maule',
    Constitución: 'Región del Maule',
    Curepto: 'Región del Maule',
    Curicó: 'Región del Maule',
    Empedrado: 'Región del Maule',
    Hualañe: 'Región del Maule',
    Licantén: 'Región del Maule',
    Linares: 'Región del Maule',
    Longaví: 'Región del Maule',
    Maule: 'Región del Maule',
    Molina: 'Región del Maule',
    Parral: 'Región del Maule',
    Pelarco: 'Región del Maule',
    Pelluhue: 'Región del Maule',
    Pencahue: 'Región del Maule',
    Rauco: 'Región del Maule',
    Retiro: 'Región del Maule',
    'Río Claro': 'Región del Maule',
    Romeral: 'Región del Maule',
    'Sagrada Familia': 'Región del Maule',
    'San Clemente': 'Región del Maule',
    'San Javier': 'Región del Maule',
    'San Rafael': 'Región del Maule',
    Talca: 'Región del Maule',
    Teno: 'Región del Maule',
    Vichuquén: 'Región del Maule',
    'Villa Alegre': 'Región del Maule',
    'Yerbas Buenas': 'Región del Maule',
    'Alto Bíobío': 'Región del Bío-Bío',
    Antuco: 'Región del Bío-Bío',
    Arauco: 'Región del Bío-Bío',
    Bulnes: 'Región del Bío-Bío',
    Cabrero: 'Región del Bío-Bío',
    Cañete: 'Región del Bío-Bío',
    Chiguayante: 'Región del Bío-Bío',
    Chillán: 'Región del Bío-Bío',
    'Chillán Viejo': 'Región del Bío-Bío',
    Cobquecura: 'Región del Bío-Bío',
    Coelemu: 'Región del Bío-Bío',
    Coihueco: 'Región del Bío-Bío',
    Concepción: 'Región del Bío-Bío',
    Contulmo: 'Región del Bío-Bío',
    Coronel: 'Región del Bío-Bío',
    Curanilahue: 'Región del Bío-Bío',
    'El Carmen': 'Región del Bío-Bío',
    Florida: 'Región del Bío-Bío',
    Hualpén: 'Región del Bío-Bío',
    Hualqui: 'Región del Bío-Bío',
    Laja: 'Región del Bío-Bío',
    Lebu: 'Región del Bío-Bío',
    'Los Álamos': 'Región del Bío-Bío',
    'Los Ángeles': 'Región del Bío-Bío',
    Lota: 'Región del Bío-Bío',
    Mulchén: 'Región del Bío-Bío',
    Nacimiento: 'Región del Bío-Bío',
    Negrete: 'Región del Bío-Bío',
    Ninhue: 'Región del Bío-Bío',
    Ñiquén: 'Región del Bío-Bío',
    Pemuco: 'Región del Bío-Bío',
    Penco: 'Región del Bío-Bío',
    Pinto: 'Región del Bío-Bío',
    Portezuelo: 'Región del Bío-Bío',
    Quilaco: 'Región del Bío-Bío',
    Quilleco: 'Región del Bío-Bío',
    Quillón: 'Región del Bío-Bío',
    Quirihue: 'Región del Bío-Bío',
    Ránquil: 'Región del Bío-Bío',
    'San Carlos': 'Región del Bío-Bío',
    'San Fabián': 'Región del Bío-Bío',
    'San Ignacio': 'Región del Bío-Bío',
    'San Nicolás': 'Región del Bío-Bío',
    'San Pedro De La Paz': 'Región del Bío-Bío',
    'San Rosendo': 'Región del Bío-Bío',
    'Santa Bárbara': 'Región del Bío-Bío',
    'Santa Juana': 'Región del Bío-Bío',
    Talcahuano: 'Región del Bío-Bío',
    Tirúa: 'Región del Bío-Bío',
    Tomé: 'Región del Bío-Bío',
    Trehuaco: 'Región del Bío-Bío',
    Tucapel: 'Región del Bío-Bío',
    Yumbel: 'Región del Bío-Bío',
    Yungay: 'Región del Bío-Bío',
    Angol: 'Región de la Araucanía',
    Carahue: 'Región de la Araucanía',
    Cholchol: 'Región de la Araucanía',
    Collipulli: 'Región de la Araucanía',
    Cunco: 'Región de la Araucanía',
    Curacautín: 'Región de la Araucanía',
    Curarrehue: 'Región de la Araucanía',
    Ercilla: 'Región de la Araucanía',
    Freire: 'Región de la Araucanía',
    Galvarino: 'Región de la Araucanía',
    Gorbea: 'Región de la Araucanía',
    Lautaro: 'Región de la Araucanía',
    Loncoche: 'Región de la Araucanía',
    Lonquimay: 'Región de la Araucanía',
    'Los Sauces': 'Región de la Araucanía',
    Lumaco: 'Región de la Araucanía',
    Melipeuco: 'Región de la Araucanía',
    'Nueva Imperial': 'Región de la Araucanía',
    'Padre Las Casas': 'Región de la Araucanía',
    Perquenco: 'Región de la Araucanía',
    Pitrufquén: 'Región de la Araucanía',
    Pucón: 'Región de la Araucanía',
    Purén: 'Región de la Araucanía',
    Renaico: 'Región de la Araucanía',
    Saavedra: 'Región de la Araucanía',
    Temuco: 'Región de la Araucanía',
    'Teodoro Schmidt': 'Región de la Araucanía',
    Toltén: 'Región de la Araucanía',
    Traiguén: 'Región de la Araucanía',
    Victoria: 'Región de la Araucanía',
    Vilcún: 'Región de la Araucanía',
    Villarrica: 'Región de la Araucanía',
    Ancud: 'Región de Los Lagos',
    Calbuco: 'Región de Los Lagos',
    Castro: 'Región de Los Lagos',
    Chaitén: 'Región de Los Lagos',
    Chonchi: 'Región de Los Lagos',
    Cochamó: 'Región de Los Lagos',
    'Curaco De Vélez': 'Región de Los Lagos',
    Dalcahue: 'Región de Los Lagos',
    Fresia: 'Región de Los Lagos',
    Frutillar: 'Región de Los Lagos',
    Futaleufú: 'Región de Los Lagos',
    Hualaihué: 'Región de Los Lagos',
    Llanquihue: 'Región de Los Lagos',
    'Los Muermos': 'Región de Los Lagos',
    Maullín: 'Región de Los Lagos',
    Osorno: 'Región de Los Lagos',
    Palena: 'Región de Los Lagos',
    'Puerto Montt': 'Región de Los Lagos',
    'Puerto Octay': 'Región de Los Lagos',
    'Puerto Varas': 'Región de Los Lagos',
    Puqueldón: 'Región de Los Lagos',
    Purranque: 'Región de Los Lagos',
    Puyehue: 'Región de Los Lagos',
    Queilén: 'Región de Los Lagos',
    Quellón: 'Región de Los Lagos',
    Quemchi: 'Región de Los Lagos',
    Quinchao: 'Región de Los Lagos',
    'Río Negro': 'Región de Los Lagos',
    'San Juan De La Costa': 'Región de Los Lagos',
    'San Pablo': 'Región de Los Lagos',
    Aysén: 'Región de Aysén del General Carlos Ibáñez del Campo',
    'Chile Chico': 'Región de Aysén del General Carlos Ibáñez del Campo',
    Cisnes: 'Región de Aysén del General Carlos Ibáñez del Campo',
    Cochrane: 'Región de Aysén del General Carlos Ibáñez del Campo',
    Coyhaique: 'Región de Aysén del General Carlos Ibáñez del Campo',
    Guaitecas: 'Región de Aysén del General Carlos Ibáñez del Campo',
    'Lago Verde': 'Región de Aysén del General Carlos Ibáñez del Campo',
    Ohiggins: 'Región de Aysén del General Carlos Ibáñez del Campo',
    'Río Ibañez': 'Región de Aysén del General Carlos Ibáñez del Campo',
    Tortel: 'Región de Aysén del General Carlos Ibáñez del Campo',
    'Cabo De Hornos': 'Región de Magallanes y la Antártica Chilena',
    'Laguna Blanca': 'Región de Magallanes y la Antártica Chilena',
    Natales: 'Región de Magallanes y la Antártica Chilena',
    Porvenir: 'Región de Magallanes y la Antártica Chilena',
    Primavera: 'Región de Magallanes y la Antártica Chilena',
    'Punta Arenas': 'Región de Magallanes y la Antártica Chilena',
    'Río Verde': 'Región de Magallanes y la Antártica Chilena',
    'San Gregorio': 'Región de Magallanes y la Antártica Chilena',
    Timaukel: 'Región de Magallanes y la Antártica Chilena',
    'Torres Del Paine': 'Región de Magallanes y la Antártica Chilena',
    Alhué: 'Región Metropolitana',
    Buin: 'Región Metropolitana',
    'Calera De Tango': 'Región Metropolitana',
    Curacaví: 'Región Metropolitana',
    'El Monte': 'Región Metropolitana',
    'Isla De Maipo': 'Región Metropolitana',
    'María Pinto': 'Región Metropolitana',
    Melipilla: 'Región Metropolitana',
    Paine: 'Región Metropolitana',
    Peñaflor: 'Región Metropolitana',
    'San José Maipo': 'Región Metropolitana',
    'Padre Hurtado': 'Región Metropolitana',
    'Pedro Aguirre Cerda': 'Región Metropolitana',
    Peñalolén: 'Región Metropolitana',
    Pirque: 'Región Metropolitana',
    Providencia: 'Región Metropolitana',
    Pudahuel: 'Región Metropolitana',
    'Puente Alto': 'Región Metropolitana',
    Quilicura: 'Región Metropolitana',
    'Quinta Normal': 'Región Metropolitana',
    Recoleta: 'Región Metropolitana',
    Renca: 'Región Metropolitana',
    'San Bernardo': 'Región Metropolitana',
    'San Joaquín': 'Región Metropolitana',
    'San Miguel': 'Región Metropolitana',
    'San Pedro': 'Región Metropolitana',
    'San Ramón': 'Región Metropolitana',
    Santiago: 'Región Metropolitana',
    Vitacura: 'Región Metropolitana',
    Cerrillos: 'Región Metropolitana',
    'Cerro Navia': 'Región Metropolitana',
    Colina: 'Región Metropolitana',
    Conchalí: 'Región Metropolitana',
    'El Bosque': 'Región Metropolitana',
    'Estación Central': 'Región Metropolitana',
    Huechuraba: 'Región Metropolitana',
    Independencia: 'Región Metropolitana',
    'La Cisterna': 'Región Metropolitana',
    'La Florida': 'Región Metropolitana',
    'La Granja': 'Región Metropolitana',
    'La Pintana': 'Región Metropolitana',
    'La Reina': 'Región Metropolitana',
    Lampa: 'Región Metropolitana',
    'Las Condes': 'Región Metropolitana',
    'Lo Barnechea': 'Región Metropolitana',
    'Lo Espejo': 'Región Metropolitana',
    'Lo Prado': 'Región Metropolitana',
    Macul: 'Región Metropolitana',
    Maipú: 'Región Metropolitana',
    Nuñoa: 'Región Metropolitana',
    Talagante: 'Región Metropolitana',
    'Tíl Tíl': 'Región Metropolitana',
    Corral: 'Región de Los Ríos',
    Futrono: 'Región de Los Ríos',
    'La Unión': 'Región de Los Ríos',
    'Lago Ranco': 'Región de Los Ríos',
    Lanco: 'Región de Los Ríos',
    'Los Lagos': 'Región de Los Ríos',
    Máfil: 'Región de Los Ríos',
    Mariquina: 'Región de Los Ríos',
    Paillaco: 'Región de Los Ríos',
    Panguipulli: 'Región de Los Ríos',
    'Río Bueno': 'Región de Los Ríos',
    Valdivia: 'Región de Los Ríos',
    Arica: 'Región de Arica y Parinacota',
    Camarones: 'Región de Arica y Parinacota',
    'General Lagos': 'Región de Arica y Parinacota',
    Putre: 'Región de Arica y Parinacota',
  };
  return regiones[comuna];
};