import { takeLatest, call, put } from "redux-saga/effects";
import axios from "axios";
import { getRecaptchaToken } from "./RecaptchaToken";

export function* watcherInsuranceComparator() {
  yield takeLatest("INSURANCE_COMPARATOR_REQUEST", getInsuranceComparator);
}

async function fetchInsuranceComparator({
  numPersonas,
  edadMaxima,
  vmMaximo,
  vmMinimo,
  strSeguros,
  sitio
}) {
  const recaptchaToken = await getRecaptchaToken();
  return axios({
    method: "get",
    url: `${process.env.REACT_APP_URI_SERVER}/api/Public/Seguros/ComparaSeguro`,
    params: {
      numPersonas,
      edadMaxima,
      vmMaximo,
      vmMinimo,
      strSeguros,
      sitio:6
    },
    headers: {
      'Recaptcha-Token': recaptchaToken, 
    }
  });
}

function* getInsuranceComparator(action) {
  try {
    const response = yield call(fetchInsuranceComparator, action.params);
    const data = response.data;
    yield put({ type: "INSURANCE_COMPARATOR_SUCCESS", data });
  } catch (error) {
    yield put({ type: "INSURANCE_COMPARATOR_FAILURE", error });
  }
}
