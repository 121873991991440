import React, {Component} from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Pasajero } from './Pasajero';
import Grid from '@mui/material/Grid';

export class Pasajeros extends Component {
    
    onTriggerEdit = (event) => {
        this.props.parentCallback(event); 
    }
    render(){              
      return (
        <div>
            <Accordion defaultExpanded={true}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>Registro</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={3}>
                        {/* <Grid item xs={12} sm={4} align="left">
                            <Pasajero pasajero={this.pasajerodummy}></Pasajero>                    
                        </Grid>     
                        <Grid item xs={12} sm={4} align="left">
                            <Pasajero pasajero={this.pasajerodummy2}></Pasajero>                    
                        </Grid>     
                        <Grid item xs={12} sm={4} align="left">
                            <Pasajero pasajero={this.pasajerodummy3}></Pasajero>                    
                        </Grid>      */}
                { this.props.pasajeros.map((item, index) => ( 
                        <Grid item xs={12} md={4} key={index} align="left">
                            <Pasajero pasajero={item} numPasajeros={this.props.pasajeros.length} indicePasajeroMostrar={index+1} parentCallback={this.onTriggerEdit}></Pasajero>                    
                        </Grid>           
                       
                    ))
                }
                    </Grid>
                </AccordionDetails>
            </Accordion>            
        </div>         
      );
    }
  }
  export default Pasajeros;