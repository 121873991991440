const TIPODOCUMENTOS_REQUEST = "TIPODOCUMENTOS_REQUEST";
const TIPODOCUMENTOS_SUCCESS = "TIPODOCUMENTOS_SUCCESS";
const TIPODOCUMENTOS_FAILURE = "TIPODOCUMENTOS_FAILURE";

const initialState = {
  fetching: false,
  data: null,
  error: null,
};

const TipoDocumentos = (state = initialState, action) => {
  switch (action.type) {
    case TIPODOCUMENTOS_REQUEST:
      return { ...state, fetching: true, error: null };
    case TIPODOCUMENTOS_SUCCESS:
      return { ...state, fetching: false, data: action.data };
    case TIPODOCUMENTOS_FAILURE:
      return { ...state, fetching: false, data: null, error: action.error };
    default:
      return state;
  }
};

export default TipoDocumentos;
