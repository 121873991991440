import TagManager from 'react-gtm-module'
import ReactGA from "react-ga4";
import Router from "./Router"

function App() {  
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM
  }
  TagManager.initialize(tagManagerArgs);
  ReactGA.initialize(process.env.REACT_APP_GA4);
  return (
    <>
    <Router />
    </>  
  );
}

export default App;

