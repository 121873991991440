import { all, fork } from "redux-saga/effects";
import { watcherInsuranceUF } from "./InsuranceUF";
import { watcherInsuranceTypes } from "./InsuranceTypes";
import { watcherInsuranceByTypeInsurance } from "./InsuranceByTypeInsurance";
import { watcherInsuranceComparator } from "./InsuranceComparator";
import { watcherPaises, watcherEstados, watcherCiudades, watcherTipoDocumentos } from "./ListasCarga";
import { watcherSms } from "./Sms";

export default function* rootSaga() {
  yield all([
    fork(watcherInsuranceUF),
    fork(watcherInsuranceTypes),
    fork(watcherInsuranceByTypeInsurance),
    fork(watcherInsuranceComparator),
    fork(watcherPaises),
    fork(watcherEstados),
    fork(watcherCiudades),
    fork(watcherTipoDocumentos),
    fork(watcherSms)
  ]);
}
