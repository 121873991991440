import { takeLatest, call, put } from "redux-saga/effects";
import axios from "axios";
import { getRecaptchaToken } from "./RecaptchaToken";

export function* watcherInsuranceByTypeInsurance() {
  yield takeLatest(
    "INSURANCE_BYTYPE_INSURANCE_REQUEST",
    getInsuranceByTypeInsurance
  );
}

async function fetchInsuranceByTypeInsurance({
  numPersonas,
  edadMaxima,
  fechaInicio,
  fechaFin,
  strTipoSeguro,
  sitio,
  recaptchaToken,
}) {
  try {
    const token = await getRecaptchaToken();

   return axios({
      method: "get",
      url: `${process.env.REACT_APP_URI_SERVER}/api/Public/Seguros/SegurosViajesByTipoSeguro`,
      params: {
        numPersonas,
        edadMaxima,
        fechaInicio,
        fechaFin,
        strTipoSeguro,
        sitio,
      },
      headers: {
       'recaptcha-token': token, 
      },
    });
  } catch (error) {
    console.error("Error fetching insurance by type:", error);
    throw error;
  }
}

function* getInsuranceByTypeInsurance(action) {
  try {
    const response = yield call(fetchInsuranceByTypeInsurance, action.params);
    const data = response.data;
    yield put({ type: "INSURANCE_BYTYPE_INSURANCE_SUCCESS", data });
  } catch (error) {
    yield put({ type: "INSURANCE_BYTYPE_INSURANCE_FAILURE", error });
  }
}
