import '../Styles/Footer.css'
import logo2 from "../imagenes/Home/METLIFE-logo.svg"
import logo from "../imagenes/Home/logo-banco-estado.png"

const Footer = () => {
    return (
      <div id="ft">
        <div className="container footer">      
        </div>
        <br/>
        <div id="ftlegal"  className="fdivtextolegal  ">
          <ul  className="social_footer_ul ">
          <p className="container foparrafo">
            <br/>
            Seguro intermediado por BancoEstado Corredores de Seguros S.A. La Compañía que asume el riesgo es MetLife Chile Seguros de Vida S.A. condiciones depositadas en la CMF bajo los siguientes códigos POL 3 2014 0335, letra A. y POL 3 2014 0335, Art. 2 Letra G. Oferta Válida para contratar hasta el 31/12/2024.
          </p>
          </ul>
          <div className='container'>
          <div className="fdivlogo">
            <img src={logo} className="flogo1" alt="MetLife"/>
            <img src={logo2} className="flogo2" alt="MetLife"/>
          </div>
          <br/>
          <br/>
          </div>
        </div>
        <input id="version" name="version" type="hidden" value={"V" + process.env.REACT_APP_VERSION} />
      </div>
    );
  }
export default Footer
