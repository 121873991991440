import { useState, useCallback } from "react";
import Inicio from "./Inicio"
import JustoNecesitas from "./JustoNecesitas"
import SeccionRenata from "./SeccionRenata"
import Condiciones from "./Condiciones"
import BeneficiosCondiciones from "./BeneficiosCondiciones"
import Footer from "./Footer"
import '../App.css';

function Landing() {    
  return (
    <>
    <Inicio/>
    <JustoNecesitas/>
    <SeccionRenata/>
    <BeneficiosCondiciones/>
    <Condiciones/>
    <Footer/> 
    </>
  );
}

export default Landing;

