import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

function fechaString(fecha) {
  return new Date(fecha).toLocaleString('es-CL', { timeZone: 'UTC', dateStyle: 'medium'}).split('-').join('/');
}
function ApiUF(props) {
  const dispatch = useDispatch();
  const insuranceUF = useSelector((state) => state.InsuranceUF);

  useEffect(() => {
    dispatch({
      type: "INSURANCE_UF_REQUEST",
    });
  }, [dispatch]);

  return (
    <li className="Uf">
    {!insuranceUF.fetching && insuranceUF.data && ( <span>(*) Valor UF de ${insuranceUF.data.value.toLocaleString("de-DE")} del día {fechaString(insuranceUF.data.id.publicationDate)}; (**) Valor USD $810 Referencial.</span>
    )}
    </li>     
  );
}
export default ApiUF;