import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { Button, Alert } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import ReactGA from "react-ga4";
import usePasajerosStore from "../../store/usePasajerosStore";
import { getRecaptchaToken } from "../../sagas/RecaptchaToken";
import { useHistory } from "react-router-dom";

export default function Review() {
  const { datosPasajeros, idCompra } = usePasajerosStore.getState();  
  const history = useHistory();

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.removeItem("_grecaptcha");
      localStorage.removeItem("buyOrder");
      localStorage.removeItem("pasoApagar");
      localStorage.removeItem("paymentFormData");
      localStorage.removeItem("respuestaPay");
      localStorage.removeItem("tipoSeguro");
      localStorage.removeItem("tjw");      
      history.push("/");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  setTimeout(() => {
    localStorage.removeItem("datosForm");
  }, 500);

  useEffect(() => {
    ReactGA.event({ action: "Pago Realizado", category: "Formulario" });
  }, []);

  const handleDownloadClick = async () => {
    const recaptchaToken = await getRecaptchaToken();
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URI_SERVER_LOCAL}/General/Certificado/Certificado/${idCompra}`,
        {
          responseType: "blob",
          withCredentials: true, 
          headers: {
            "Recaptcha-Token": recaptchaToken,
          },
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Certificado.pdf");
      document.body.appendChild(link);
      link.click();

      localStorage.removeItem("_grecaptcha");
      localStorage.removeItem("buyOrder");
      localStorage.removeItem("pasoApagar");
      localStorage.removeItem("paymentFormData");
      localStorage.removeItem("respuestaPay");
      localStorage.removeItem("tipoSeguro");
      localStorage.removeItem("tjw");
    } catch (error) {
      console.error("Error descargando el certificado:", error);
    }
  };

  return (
    <Box sx={{ padding: "10%" }}>
      {Array.isArray(datosPasajeros) && datosPasajeros.length > 0 ? (
        <Typography variant="h4" gutterBottom>
          Gracias{" "}
          {`${datosPasajeros[0].nombre} ${datosPasajeros[0].apellido1} ${
            datosPasajeros[0].apellido2 || ""
          }`}
          !
        </Typography>
      ) : (
        <Typography variant="h4" gutterBottom>
          Gracias!!!
        </Typography>
      )}

      <Grid container spacing={0} sx={{ my: "4%" }}>
        <Grid item sm={12} align="center">
          <Typography variant="p" gutterBottom>
            Acabas de recibir el Certificado de Cobertura en tu correo
            electrónico y también lo puedes descargar a continuación.
          </Typography>
        </Grid>
        <Grid item sm={12} align="center" sx={{ m: "4%" }}>
          <Button
            type="button"
            variant="contained"
            onClick={handleDownloadClick}
          >
            Descargar certificado aquí
          </Button>
        </Grid>
        <Grid item sm={12}>
          <Alert severity="info">
            Una vez descargado el certificado de cobertura de tu Seguro viaje +
            protegido, podrás revisarlo ingresando una clave la cual corresponde
            al número de tu Rut sin guión ni dígito verificador.
          </Alert>
        </Grid>
        <Grid item sm={12} align="center">
          <label className="strongLabel">
            Recibirás también tu voucher de asistencia en el mismo mail dentro
            de los próximos minutos.
          </label>
        </Grid>
      </Grid>
    </Box>
  );
}
