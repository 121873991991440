const CERTIFICADO_REQUEST = "CERTIFICADO_REQUEST";
const CERTIFICADO_SUCCESS = "CERTIFICADO_SUCCESS";
const CERTIFICADO_FAILURE = "CERTIFICADO_FAILURE";

const initialState = {
  fetching: false,
  data: null,
  error: null,
};

const Certificado = (state = initialState, action) => {
  switch (action.type) {
    case CERTIFICADO_REQUEST:
      return { ...state, fetching: true, error: null };
    case CERTIFICADO_SUCCESS:
      return { ...state, fetching: false, data: action.data };
    case CERTIFICADO_FAILURE:
      return { ...state, fetching: false, data: null, error: action.error };
    default:
      return state;
  }
};

export default Certificado;
