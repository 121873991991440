import React from 'react'
import { Button } from '@mui/material';
import { Col, Row } from 'react-bootstrap'

const styles = {
  bold: {
    fontWeight: 'bold',
    marginBottom: '-5px',
    fontFamily: 'sans-serif',
  },
  boldUnderline: {
    fontWeight: 'bold',
    marginBottom: '-5px',
    fontFamily: 'sans-serif',
    textDecoration: 'underline',
  },
  mt3: {
    marginTop: '1.2em',
  },
  mt4: {
    marginTop: '1.75em',
  },
  mtl5: {
    marginBottom: '-5px',
  },
  text: {
    fontSize: '18px',
    fontFamily: 'sans-serif',
  },
  hr: {
    height: 0,
    '-webkit-box-sizing': 'content-box',
    '-moz-box-sizing': 'content-box',
    boxSizing: 'content-box',
    borderTop: '1.2px solid black',
    marginTop: '20px',
    marginBottom: '20px',
    // border: '0'
  },
  textCenter: {
    textAlign: 'center'
  },
  justify: {
    textAlign: 'justify'
  }
};


const PDFSeguro = () => {
  return (
    <Row style={{ height: '50vh', overflowX: 'auto', padding: '1em 1.5em' }}>
      <Col xs={12} sm={12} md={12} lg={12} className='text-justify'>
        <h4>¿Qué cubre este seguro?</h4>
        <p>
        La compañía aseguradora pagará a los herederos legales el capital asegurado según plan contratado, si el asegurado de esta póliza falleciera como consecuencia directa e inmediata de un accidente. Cada asegurado no podrá contratar más de un seguro por viaje. Se define por accidente todo suceso imprevisto, involuntario, repentino y fortuito, causado por medios externos y de modo violento, ocasionando al asegurado una o más lesiones (visibles o internas). Se incluyen: ahogamiento, asfixia, torceduras y desgarramientos; también septicemias e infecciones a raíz de heridas involuntarias y hayan penetrado en el organismo del asegurado.{' '}
        </p>
        <p>A su vez, la compañía reembolsará a los asegurados adicionales los gastos médicos y farmacéuticos razonables, tanto ambulatorios como hospitalarios, en el que el Asegurado incurra a consecuencia de un accidente, con monto máximo de UF 15 (evento único durante el viaje) una vez que haya hecho uso de su sistema de salud previsional y cualquier otro seguro convenio o plan de beneficios.{' '}</p>
        <h4>¿Qué no cubre este seguro?</h4>        
        <p>
            No se cubrirá la muerte accidental o reembolso de gastos médicos por accidente a consecuencia de:
        </p>
        <ul className="bclu">
          <li>Efectos de guerra, declarada o no declarada, invasión, acción de un enemigo extranjero, hostilidades u operaciones bélicas, ya sea con o sin declaración de guerra, así como tampoco ningún ejercicio o práctica de guerra.</li>
          <li>Suicidio, intento de suicidio, o heridas auto inferidas, ya sea que el asegurado haya estado en su pleno juicio o enajenado mentalmente.</li>
          <li>Prestación de servicios del asegurado en las Fuerzas Armadas o funciones policiales de cualquier tipo. Para todos los efectos de esta póliza las funciones de policía incluyen además las funciones de policía civil y gendarmería.</li>
          <li>Intoxicación o encontrarse el asegurado bajo los efectos de cualquier narcótico o droga a menos que hubiese sido administrado por prescripción médica, y la conducción de cualquier vehículo por parte del asegurado, encontrándose éste en estado de ebriedad, conforme a los límites establecidos en la normativa vigente a la fecha del siniestro. Dichos estados deberán acreditarse mediante la documentación expedida por los organismos correspondientes.</li>
          <li>Negligencia o imprudencia o culpa grave del asegurado.</li>
          <li>Movimientos sísmicos desde el grado 8 inclusive en la escala de Mercalli, determinado por el Servicio Sismológico del Departamento de Centro Sismológico Nacional o del servicio que en el futuro lo reemplace.</li>
          <li>Viaje o vuelo en vehículo aéreo en un transporte no sujeto a itinerario, ni operado por una empresa de transporte aéreo comercial sobre una ruta establecida.</li>
          <li>Fractura de Huesos producida como consecuencia directa e inmediata de osteoporosis.</li>
        </ul>{' '}        
        <h4>Vigencia del seguro</h4>        
        <p>
        Este seguro tendrá una vigencia durante el viaje del asegurado titular, contados a partir de la fecha de inicio del viaje. La cobertura cesará si el asegurado fallece y/o termine el viaje del cliente.
        </p>
        <h4>Requisitos de Asegurabilidad</h4>        
        <p>
        El asegurado podrá contratar este seguro desde los 18 años, hasta los 68 años y 364 días. La edad máxima de permanencia del seguro es de 69 años y 364 días.{' '}
        </p>
        <h4>Denuncio de Siniestros</h4>        
        <p>Para la cobertura de muerte accidental, el Beneficiario deberá ingresar el denuncio llamando al 600 660 1212; ingresando al sitio web www.segurosbancoestado.cl, en cualquier sucursal de BancoEstado, ante la corredora de seguros. Debe adjuntar la copia del parte policial, que dé cuenta del siniestro y/o otros documentos que puedan ser solicitados. El beneficiario tendrá un plazo de 240 días para ingresar el denuncio. El plazo de liquidación y pago del siniestro es de 10 días hábiles, contados desde el ingreso del denuncio, siempre y cuando, se cuente con toda la documentación.{' '}</p>
        <p>Para la cobertura de reembolsos por gastos médicos accidental el ingreso de la documentación expuesta se debe realizar a través del portal web https://portaldeclientes.metlife.cl disponibilizado por la compañía de seguros.{' '}</p>
        <p>Para ingresar al portal de clientes MetLife, el titular del seguro deberá crear su usuario en el portal indicado anteriormente, donde podrá adjuntar toda la información solicitada para la evaluación y liquidación de su siniestro.{' '}</p>
        <p><b>Importante:</b> Sin perjuicio de lo anterior, la Compañía, si lo estima necesario, podrá solicitar o requerir otros documentos o antecedentes que le permitan evaluar el siniestro denunciado.{' '}</p>
        <h4>Condiciones Generales</h4>
        <p>        
        Este producto se rige bajo los siguientes códigos POL 3 2014 0335, letra A. y POL 3 2014 0335, Art. 2 Letra G. {' '}
        </p>
      </Col>
    </Row>
  );
};

export default PDFSeguro;
