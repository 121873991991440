import React,{Component,useState, useEffect} from 'react';
import {useHistory, useLocation} from 'react-router';
import {Typography,Button, Fab} from '@mui/material';
import PasajeroForm from './PasajeroForm';
import PasajeroFormEdit from './PasajeroFormEdit';
import { Pasajeros } from './Pasajeros';
import Paper from '@mui/material/Paper';
import Alert from '@mui/material/Alert';
import Swal from 'sweetalert2';
import usePasajerosStore from "../../store/usePasajerosStore";

const { setDatosPasajeros, datosPasajeros } = usePasajerosStore.getState();

export class AddressForm extends Component  {    
  infoPreviousPasajeros = datosPasajeros;
  constructor(props){
    super(props);
    this.pasajeroFormRef = React.createRef();
    var npasajero = {
      indice: 1,
      nombre: "",
      apellido: "", 
      tipoDocumento: "",
      identificacion: "", 
      direccion: "", 
      ciudad: "", 
      pais: "", 
      fechanacimiento: "",//event.target.fechanacimiento.value,
      provincia: "", 
      email: "", 
      nombresEmergencia: "", 
      telefonoEmergencia: "", 
      apellidosEmergencia: "",
      mismosDatos: false
    };
    if(this.infoPreviousPasajeros!==undefined && Array.isArray(this.infoPreviousPasajeros)){
      npasajero.indice=this.infoPreviousPasajeros.length+1;
      if(this.infoPreviousPasajeros.length>0){
        if(this.infoPreviousPasajeros[0].mismosDatos){
          npasajero.ciudad= this.infoPreviousPasajeros[0].ciudad; 
          npasajero.pais= this.infoPreviousPasajeros[0].pais; 
          npasajero.provincia= this.infoPreviousPasajeros[0].provincia; 
          npasajero.nombresEmergencia= this.infoPreviousPasajeros[0].nombresEmergencia;
          npasajero.direccion= this.infoPreviousPasajeros[0].direccion;
          npasajero.telefonoEmergencia= this.infoPreviousPasajeros[0].telefonoEmergencia;
          npasajero.apellidosEmergencia= this.infoPreviousPasajeros[0].apellidosEmergencia;
        }
      }
      this.state = {
        Pasajeros:  this.infoPreviousPasajeros,
        numPasajero:this.infoPreviousPasajeros.length+1,      
        pasajeroSelected: npasajero,
        MaxNumPasajeros: this.props.MaxNumPasajeros,
        editando: false,
        posicionEdit:0
      }
    }else{   
      //inicializar datospasajeros
      setDatosPasajeros([]);  
      this.state ={
        Pasajeros:  [],
        numPasajero:1,      
        pasajeroSelected: npasajero,
        MaxNumPasajeros: this.props.MaxNumPasajeros,
        editando: false,
        posicionEdit:0
      }
    }
    
    // window.dataLayer.push({
    //   "event":"formularioDatosPasajero"
    // });
    // this.history.replace({ pathname: `/DatosPasajero/${this.infoPrevious.plan.nombreSeguro}`});
  }

  handleCallback = (childData) =>{
    var npasajero = {
      indice: this.state.numPasajero + 1,
      nombre: "",
      apellido: "", 
      tipoDocumento: "",
      identificacion: "", 
      direccion: childData.mismosDatos ? childData.direccion : "",  
      ciudad: childData.mismosDatos ? childData.ciudad : "", 
      pais: childData.mismosDatos ? childData.pais : "", 
      fechanacimiento: "",//event.target.fechanacimiento.value,
      provincia: childData.mismosDatos ? childData.provincia : "", 
      email: "", 
      nombresEmergencia: childData.mismosDatos ? childData.nombresEmergencia : "", 
      telefonoEmergencia: childData.mismosDatos ? childData.telefonoEmergencia : "", 
      apellidosEmergencia: childData.mismosDatos ? childData.apellidosEmergencia : "",
      mismosDatos: childData.mismosDatos
    };
    var condicion = false;
    var pasajerosTemp = datosPasajeros;  
    var npas = Array.isArray(pasajerosTemp) ? pasajerosTemp.find(x=>x.indice===childData.indice) : null;
    if(npas!==null && npas!==undefined){
      condicion=true;
      childData.indice=childData.indice+1;
    }else {
      npas = this.state.Pasajeros.find(x=>x.indice===childData.indice);
      if(npas!==null && npas!==undefined){
        condicion=true;
        childData.indice=childData.indice+1;
      }
    }
    // if(this.state.Pasajeros!=undefined && this.state.Pasajeros!=null){
    //   do{
    //     var npas = this.state.Pasajeros.find(x=>x.indice===childData.indice);
    //     if(npas!==null && npas!==undefined){
    //       condicion=true;
    //       childData.indice=childData.indice+1;
    //     }
    //   }while(condicion);
    // }
    var nlistaPasajeros = [...this.state.Pasajeros, childData];
    this.setState({
      Pasajeros: (
        nlistaPasajeros
      ),
      pasajeroSelected: npasajero,
      numPasajero: this.state.numPasajero + 1
    });
    setDatosPasajeros(nlistaPasajeros);
    if(!this.state.editando){
      this.setState({
        editando: true
      }, () => {
        this.setState({
          editando: false
        });
      });     
    }
    this.props.parentCallback(); 
  }

  handleCallbackEdit = (childData, tipo) =>{
    var npasajero = {
      indice: this.state.numPasajero + 1,
      nombre: "",
      apellido: "", 
      tipoDocumento: "",
      identificacion: "", 
      direccion: childData.mismosDatos ? childData.direccion : "",  
      ciudad: childData.mismosDatos ? childData.ciudad : "", 
      pais: childData.mismosDatos ? childData.pais : "", 
      fechanacimiento: "",//event.target.fechanacimiento.value,
      provincia: childData.mismosDatos ? childData.provincia : "", 
      email: "", 
      nombresEmergencia: childData.mismosDatos ? childData.nombresEmergencia : "", 
      telefonoEmergencia: childData.mismosDatos ? childData.telefonoEmergencia : "", 
      apellidosEmergencia: childData.mismosDatos ? childData.apellidosEmergencia : "",
      mismosDatos: childData.mismosDatos
    };
    if(tipo==="r"){
      this.setState({
        pasajeroSelected: npasajero,
        editando:false
      });
    }else{      
      var hto = this.state.Pasajeros;
      var esTitular = false;
      var mismosDa = false;
      for(var i=0;i<hto.length;i++){
        if(hto[i].indice ===  childData.indice){
          hto[i] = childData;
          if(i==0){
            esTitular=true;
            if(hto[i].mismosDatos === "true") mismosDa = true;
          }
        }
        if(i>0 && esTitular && mismosDa){
          hto[i].nombresEmergencia = childData.nombresEmergencia;
          hto[i].apellidosEmergencia = childData.apellidosEmergencia;
          hto[i].telefonoEmergencia = childData.telefonoEmergencia;
          hto[i].direccion = childData.direccion;
        }
      }
      this.setState({
        Pasajeros: hto,
        pasajeroSelected: npasajero,
        editando:false
      });
      setDatosPasajeros(hto);
    }
    this.props.parentCallback(); 
  }

  handleCallbackEditDelPasajero = (childData) =>{
    var npas = this.state.Pasajeros.find(x=>x.indice===childData.indice);    
    var posicion = this.state.Pasajeros.findIndex(x=>x.indice===childData.indice) +1;
    if(childData.accion==="edit"){            
      this.setState({
        pasajeroSelected: npas,
        posicionEdit:posicion
      });
      this.setState({
        editando: false
      }, () => {
        this.setState({
          editando: true
        });
        
      });     
      
    }else if(this.state.editando){
      Swal.fire({
        title: "ALERTA",
        text: "No puede eliminar pasajeros mientras este editando",
        icon:"warning",                
        showCloseButton: true,
        showCancelButton: false,
        focusConfirm: true,
        confirmButtonText: 'Ok'
    });
    }else{
      var listaTmp =  this.state.Pasajeros.filter(x => x.indice!==childData.indice);
      this.setState({
        Pasajeros: listaTmp
      });
      setDatosPasajeros(listaTmp);
      if(!this.state.editando){
        this.setState({
          editando: true
        }, () => {
          this.setState({
            editando: false
          });
        });     
      }
    }    
    this.props.parentCallback(); 
  }
  
  invokeAgregarPasajero = () => {
    if (this.pasajeroFormRef.current) {
      this.pasajeroFormRef.current.onCustomSubmit();
    }
  };

  render(){        
    return (
      <React.Fragment>
        <Paper elevation={0} align="center">
          <Typography variant="h6" gutterBottom>
          Ingresa los siguientes datos
          </Typography>          
          <Typography variant="label" gutterBottom align='center'>
            Todos los campos son obligatorios
          </Typography>
        </Paper>
        {Array.isArray(this.state.Pasajeros) && this.state.Pasajeros.length<5 && !this.state.editando 
          && this.state.Pasajeros.length < this.state.MaxNumPasajeros ?           
          <PasajeroForm ref={this.pasajeroFormRef} parentCallback = {this.handleCallback} pasajero={this.state.pasajeroSelected} indiceMostrar={this.state.Pasajeros.length+1} maxNumPasajeros={this.props.MaxNumPasajeros} ></PasajeroForm>
          :
          this.state.editando ?
          <div></div>
          :
          <Alert severity="info">Has registrado {this.state.MaxNumPasajeros === 1 ? this.state.MaxNumPasajeros+" pasajero" : this.state.MaxNumPasajeros +" pasajeros"}.
            Para continuar, haz clic en el botón Siguiente.
          </Alert>
        }       
        {
          this.state.editando && 
          <PasajeroFormEdit parentCallback = {this.handleCallbackEdit} pasajero={this.state.pasajeroSelected} indiceMostrar={this.state.posicionEdit} maxNumPasajeros={this.props.MaxNumPasajeros}></PasajeroFormEdit>                    
        }     
        <Pasajeros pasajeros = {this.state.Pasajeros} parentCallback = {this.handleCallbackEditDelPasajero}></Pasajeros>
            
      </React.Fragment>
    );
  }
}
export default AddressForm;
