import "../Styles/Planes.css";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import swal from "sweetalert";
import ReactGA from "react-ga4";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { loadFormData } from "../reducers/FormReducer";
import usePasajerosStore from "../store/usePasajerosStore";

const MAX_SELECTABLE = 3;
const DEFAULT_CARDS_TO_SHOW = 3;

const Planes = ({
  data,
  click,
  index,
  watchAll,
  selectedList,
  numPeople,
  maxAge,
  minDate,
  maxDate,
  amounts,
}) => {
  const formState = useSelector((state) => state.FormReducer);
  const { tjw, initializeStore } = usePasajerosStore();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    idSeguro,
    nombreSeguro,
    cobertura,
    valorSeguro,
    clinica1,
    valorPrima,
    condiciones,
  } = data;
  const [value, setValue] = React.useState(false);
  const [numper, setNumper] = useState(numPeople);
  const [edad, setEdad] = useState(maxAge);
  const [fechaini, setFechaini] = useState(minDate);
  const [fechafin, setFechafin] = useState(maxDate);
  const [tipoplan, setTipoplan] = useState(data);

  useEffect(() => {
    initializeStore();
  }, [initializeStore]);

  const showLoading = function () {
    Swal.fire({
      // title: 'Cargando...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      // showLoaderOnConfirm: true,
      didOpen: () => {
        Swal.showLoading();
      },
    });
  };
  

  function DividirCadena(n) {
    let arrayDeCadena = condiciones.split("/");
    let nombre = arrayDeCadena[n];
    return nombre;
  }

  function DividirCadena1(n) {
    let arrayDeCadena = nombreSeguro.split(" ");
    let nombre = arrayDeCadena[n];
    return nombre;
  }

  function DividirCadenaC(n) {
    let arrayDeCadena = cobertura.split("_");
    let nombre = arrayDeCadena[n];
    return nombre;
  }

  const onChangeValue = () => {
    if (selectedList.length <= MAX_SELECTABLE) {
      click(idSeguro, !value);
      setValue(!value);
    } else {
      if (value) {
        click(idSeguro, !value);
        setValue(!value);
      }
    }
  };

  if (index > DEFAULT_CARDS_TO_SHOW && !watchAll) {
    return null;
  }

  const guardarPlanState = async () => {
    const tokencaptcha = await executeRecaptcha("comprarPlan");
    if (tokencaptcha) {
      let datosForm = {
        minDate: minDate,
        maxDate: maxDate,
        plan: data,
        edadMaxima: maxAge,
        numPersonas: numPeople,
      };
      localStorage.setItem("datosForm", JSON.stringify(datosForm));
      localStorage.setItem("tipoSeguro", data.idTipoSeguro);
      let fechaActual = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      );
      if (minDate < fechaActual) {
        swal({
          title: "ALERTA",
          text: "La fecha de inicio del viaje debe ser mayor a la fecha de hoy",
          icon: "warning",
          button: "OK",
        });
        return "";
      }
      if (maxDate < fechaActual) {
        swal({
          title: "ALERTA",
          text: "La fecha de fin del viaje debe ser mayor a la fecha de hoy",
          icon: "warning",
          button: "OK",
        });
        return "";
      }
      try {
        if (
          numPeople !== numper ||
          maxAge !== edad ||
          minDate !== fechaini ||
          maxDate !== fechafin ||
          data !== tipoplan
        ) {
          swal({
            title: "ALERTA",
            text: "DEBE PRESIONAR EL BOTON VER PLANES",
            icon: "warning",
            button: "OK",
          });
          return "";
        }
        showLoading();
        Swal.close();
        if (tjw.length>0) {
          dispatch(loadFormData({ tjw: tjw }));          
          ReactGA.event({
            action: "Seleccionar Plan",
            category: "Planes",
            label: data.nombreSeguro,
          });
          history.push({
            pathname: "/formulario",
            state: {
              newCustomer: {
                minDate: minDate,
                maxDate: maxDate,
                plan: data,
                edadMaxima: maxAge,
                numPersonas: numPeople,
                urlOrigin: window.location.href,
              },
            },
          });
        }
      } catch (error) {
        Swal.close();
        Swal.fire({
          title: "No hay conexión con el servidor del sistema.",
          icon: "error",
        });
      }
    }
  };
  return (
    <div className="row mb-3 text-center" key={idSeguro}>
      <div className="col plcol">
        <div className="card mb-4 rounded-bottom-left-1 rounded-bottom-right-1 rounded-top-right-1 rounded-top-left-1 shadow-sm h-100 ">
          <div
            className="card-header text-white  rounded-top-left-1 rounded-top-right-1"
            style={{
              height: 100,
              backgroundColor: "#EE811E",
              borderTopLeftRadius: "0.95rem",
              borderTopRightRadius: "0.95rem",
              display: "flex",
            }}
          >
            <div style={{ margin: "auto" }}>
              <strong>
                {DividirCadena1(0)}&nbsp;{DividirCadena1(1)}&nbsp;
                {DividirCadena1(2).split("USD")}
              </strong>
              <br />
              <strong>
                {DividirCadena1(2).split("Nacional")}&nbsp;{DividirCadena1(3)}
                &nbsp;{DividirCadena1(4)}&nbsp;{DividirCadena1(5)}&nbsp;
                {DividirCadena1(6)}&nbsp;{DividirCadena1(7)}
              </strong>
              <br />
              <strong>{clinica1}</strong>
            </div>
          </div>
          <div className="card-body">
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "-1rem",
              }}
            >
              <h1
                className="card-title pricing-card-title"
                style={{ fontSize: "35px" }}
              >
                <strong>{`$${valorSeguro.toLocaleString("de-DE")}`}</strong>
              </h1>
            </div>
            <div
              className="text-muted fw-light"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <h1
                className="card-title pricing-card-title plvalor"
                style={{ textAlign: "center", fontWeight: "unset" }}
              >
                <strong>{` UF ${valorPrima}`}</strong>
              </h1>
              <small className="plasterisco">*</small>
            </div>
            <p style={{ height: 60, textAlign: "center" }}>
              <span className="plparrafo">
                {DividirCadena(0)}
                <b>{DividirCadena(1)}</b>
              </span>
            </p>
            <div className="plbotones">
              <button
                id={"cont" + idSeguro}
                type="button"
                className="w-60 btn btn-lg btn-primary mb-3 plboton"
                style={{
                  color: "white",
                  background: "#EE811E",
                  border: "none",
                  fontSize: "12.3px",
                }}
                onClick={guardarPlanState}
              >
                Quiero contratar
              </button>
              <button
                id={"detalle" + idSeguro}
                type="button"
                className="w-60 btn btn-lg btn-primary mb-3 plboton"
                style={{
                  color: "white",
                  background: "#959595",
                  border: "none",
                  fontSize: "11px",
                }}
                onClick={() => {
                  
                  ReactGA.event({
                    action: "Detalle Plan",
                    category: "Planes",
                    label: nombreSeguro,
                  });
                 
                  dispatch({
                    type: "INSURANCE_COMPARATOR_REQUEST",
                    params: {
                      numPersonas: numPeople,
                      edadMaxima: maxAge,
                      vmMaximo: amounts[1],
                      strSeguros: idSeguro,
                    },
                  });
                }}
              >
                Conoce el detalle de tu cobertura + asistencias
              </button>
              <br />
            </div>
            <p
              className=""
              style={{
                minHeight: 40,
                textAlign: "left",
                marginBottom: "-1rem",
                marginTop: "-7rem",
                marginLeft: "0.5rem",
              }}
            >
              <span className="plparrafo2">
                <b>{DividirCadenaC(0)}</b>
                {DividirCadenaC(1)}
                <b>{DividirCadenaC(2)}</b>
                {DividirCadenaC(3)}
                {DividirCadenaC(4)}
                <b>{DividirCadenaC(5)}</b>
                {DividirCadenaC(6)}
                <b>{DividirCadenaC(7)}</b>
                {DividirCadenaC(8)}
                <b>{DividirCadenaC(9)}</b>
                <b>{DividirCadenaC(10)}</b>
                <b>{DividirCadenaC(11)}</b>
                <b>{DividirCadenaC(12)}</b>
                <b>{DividirCadenaC(13)}</b>
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Planes;
