import '../Styles/BeneficiosCondiciones.css'
import Aos from "aos"
import "aos/dist/aos.css"
import React from 'react'
import isapre from "../imagenes/JustoNecesitas/Accidental.svg" 
import validochile from "../imagenes/JustoNecesitas/AsistenciaMedica.svg" 
import contratanecesitas from "../imagenes/JustoNecesitas/Teleasistencia.svg" 
import contrataminutos from "../imagenes/JustoNecesitas/PerdidaEquipaje.svg"

const BeneficiosCondiciones = () => {
  React.useEffect(() => {
    Aos.init({duration: 3000});
      }, []);
    return (
      <div id="bc">
      <div className="mt-4">
          <br />
          <h1 className="titulos">
          ¿Qué incluye tu asistencia? 
          </h1>
          <br />          
        </div>
        <br /><br />  
        <section className="container">
          <div className='bcgeneral'>
            <div className='bccol1'>
        <div className='bcprincipal'>
          <div className='bcpa'><img src={isapre} className="img-fluid bcimge" alt="" /></div>
          <div className='bchi'><h6 className='bcdescripc'><b>Gastos por cancelación<br/> o interrupción de viaje.</b></h6></div>
        </div>
        <div className='bcprincipal'>
          <div className='bcpa'><img src={contratanecesitas} className="img-fluid bcimge" alt="" /></div>
          <div className='bchi'><h6 className='bcdescripc'><b>Gastos de hotel por <br/>convalecencia y/o cuarentena.</b></h6></div>
        </div>
        <div className='bcprincipal'>
          <div className='bcpa'><img src={validochile} className="img-fluid bcimge" alt="" /></div>
          <div className='bchi'><h6 className='bcdescripc'><b>Asistencia<br/> médica en viaje.</b></h6></div>
        </div>
        </div>
        <div className='bccol2'>
        <div className='bcprincipal'>
          <div className='bcpa'><img src={isapre} className="img-fluid bcimge" alt="" /></div>
          <div className='bchi'><h6 className='bcdescripc'><b>Gastos por hospitalización<br/> y medicamentos.</b></h6></div>
        </div>
        <div className='bcprincipal'>
          <div className='bcpa'><img src={contrataminutos} className="img-fluid bcimge" alt="" /></div>
          <div className='bchi'><h6 className='bcdescripc'><b>Gastos por penalidad<br/> en reprogramación de vuelo.</b></h6></div>
        </div>
        <div className='bcprincipal'>
          <div className='bcpa'><img src={contratanecesitas} className="img-fluid bcimge" alt="" /></div>
          <div className='bchi'><h6 className='bcdescripc'><b>Repatriación<br/> sanitaria.</b></h6></div>
        </div>
        </div>
        </div>
        </section> 
        <br /><br /><br /><br />
      </div>
    );
}

export default BeneficiosCondiciones
