import { combineReducers } from "redux";
import InsuranceUF from "./InsuranceUF";
import InsuranceTypes from "./InsuranceTypes";
import InsuranceByTypeInsurance from "./InsuranceByTypeInsurance";
import InsuranceComparator from "./InsuranceComparator";
import Paises from "./Paises";
import Estados from "./Estados";
import Ciudades from "./Ciudades";
import TipoDocumentos from "./TipoDocumentos";
import Certificado from "./Certificado";
import Sms from "./Sms";
import FormReducer from "./FormReducer";
export default combineReducers({
  InsuranceUF,
  InsuranceTypes,
  InsuranceByTypeInsurance,
  InsuranceComparator,
  Paises,
  Estados,
  Ciudades,
  TipoDocumentos,
  Certificado,
  Sms,
  FormReducer
});
