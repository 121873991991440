const SMS_REQUEST = "SMS_REQUEST";
const SMS_SUCCESS = "SMS_SUCCESS";
const SMS_FAILURE = "SMS_FAILURE";

const initialState = {
  fetching: false,
  data: null,
  error: null,
};

const Sms = (state = initialState, action) => {
  switch (action.type) {
    case SMS_REQUEST:
      return { ...state, fetching: true, error: null };
    case SMS_SUCCESS:
      return { ...state, fetching: false, data: action.data };
    case SMS_FAILURE:
      return { ...state, fetching: false, data: null, error: action.error };
    default:
      return state;
  }
};

export default Sms;
