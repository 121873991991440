import React, {Component} from 'react';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import { CardHeader, Avatar, IconButton } from '@mui/material';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Swal from 'sweetalert2';
import moment from "moment";
import ReactGA from "react-ga4";

export class Pasajero extends Component {
    
    render(){   
        // const MySwal = withReactContent(Swal);   
        const {indice,nombre,apellido1,apellido2, tipoDocumento,tipoDocumentoLabel, identificacion, direccion,paisLabel,
            provinciaLabel,ciudadLabel, ciudad, pais, fechanacimiento, provincia, email,sexo,
            nombresEmergencia, telefonoEmergencia, apellidosEmergencia, mismosdatos} = this.props.pasajero;
        const numPasajeros = this.props.numPasajeros;
        const indiceMostrar= this.props.indicePasajeroMostrar;
        const onTriggerEdit = (event) => {  
            var item={
                accion:"edit",
                indice:indice
            };
            ReactGA.event({ action:"Editar Pasajero", category: "Formulario", label: identificacion});
            this.props.parentCallback(item);  
        }
        const onTriggerDelete = (event) => { 
            Swal.fire({
                title: "ALERTA",
                text: "¿Está seguro de querer eliminar el pasajero?",
                icon:"warning",                
                showCloseButton: true,
                showCancelButton: true,
                focusConfirm: false,
                confirmButtonText:
                    'Sí',
                confirmButtonAriaLabel: 'Sí',
                cancelButtonText:
                'No',
                cancelButtonAriaLabel: 'No'
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    var item={
                        accion:"del",
                        indice:indice
                    };
                    ReactGA.event({ action:"Eliminar Pasajero", category: "Formulario", label: identificacion});
                    this.props.parentCallback(item);
                    
                } else if (result.isDenied) {
                }
              });            
            // if(window.confirm('¿Está seguro de querer eliminar el pasajero?')){
                     
            // }            
        }
      return (
        <div className='cardMinHeight'>           
            <Card>
                {<div className='titular_center'>{indice===1 ? "Pasajero Titular" : "Pasajero " + indiceMostrar}</div>}
                <CardHeader
                    avatar={
                    <Avatar sx={{ bgcolor: "#EE811E" }} aria-label="indice">
                        {indiceMostrar}
                    </Avatar>
                    }
                    title={nombre + " "+ apellido1 + (apellido2 !== null && apellido2 !== "" ? (" "+ apellido2) : "")}
                    subheader= {tipoDocumentoLabel+ " - " + identificacion}
                />
                <CardContent>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    {paisLabel}
                    </Typography>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    {provinciaLabel+", "+ciudadLabel + " | "+direccion}
                    </Typography>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    {moment(fechanacimiento).format('DD/MM/YYYY') }
                    </Typography>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    {sexo==null || sexo==undefined ? "" : sexo=="M" ? "Masculino" : "Femenino"}
                    </Typography>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    {email}
                    </Typography>
                    {/* {indice===1 && */}
                    <React.Fragment>
                    <Typography sx={{ fontSize: 16 }} color="text.primary" gutterBottom>
                    Contacto de emergencia
                    </Typography>                    
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    {nombresEmergencia + " "+ apellidosEmergencia + ", telf: "+ telefonoEmergencia}
                    </Typography>
                    </React.Fragment> 
                    {/* } */}
                </CardContent>
                <CardActions style={{float:"inline-end"}}>
                    <IconButton onClick={onTriggerEdit} aria-label="Editar">
                        <EditIcon />
                    </IconButton>
                    {(indiceMostrar===numPasajeros || indiceMostrar > 1) &&
                        <IconButton aria-label="Eliminar" onClick={onTriggerDelete}>
                            <DeleteOutlineIcon />
                        </IconButton>
                    }
                </CardActions>
            </Card>                          
        </div>         
      );
    }
  }
  export default Pasajero;