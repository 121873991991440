import axios from "axios";
import { getRecaptchaToken } from "../../sagas/RecaptchaToken";
import usePasajerosStore from "../../store/usePasajerosStore";


export const calltransbank = async (form) => {
  const recaptchaToken = await getRecaptchaToken();
  const { tjw } = usePasajerosStore.getState();
  return await axios({
		url:process.env.REACT_APP_URI_SERVER_LOCAL+"/api/Pago/Wpay/RequestTransbank?valor=" + form.valor + "&order=" + form.order + "&url="+window.location.protocol+"//"+window.location.hostname + "" 
    +(window.location.port!=="" ? ":" +window.location.port:"")+ "/respuestatransbank",
		cors: true,
    method: "get",
		headers: {
			'Authorization': "Bearer " + tjw,
      'Recaptcha-Token': recaptchaToken,
		},
	}).then(({ data }) => data);  
};

export const confirm = async (token) => {
  const { tjw } = usePasajerosStore.getState(); 
  const recaptchaToken = await getRecaptchaToken();
  return await axios({
		url:process.env.REACT_APP_URI_SERVER_LOCAL+"/api/Pago/Wpay/ResponseTransbank?token=" + token,
		cors: true,
    method: "post",
		headers: {
			authorization: "Bearer " + tjw,
      'Recaptcha-Token': recaptchaToken,
		},
	}).then(({ data }) => data);
};

export const verificarCompra = async (order) => {
  const { tjw } = usePasajerosStore.getState(); 
  const recaptchaToken = await getRecaptchaToken();
  return await axios({
		url:process.env.REACT_APP_URI_SERVER_LOCAL+"/api/General/Proceso/VerificarCompra/"+order,
		cors: true,
    method: "post",
		headers: {
			authorization: "Bearer " + tjw,
      'Recaptcha-Token': recaptchaToken,
		},
	}).then(({ data }) => data);
};