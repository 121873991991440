import { create } from "zustand";
import axios from "axios";
import { getRecaptchaToken } from "../sagas/RecaptchaToken";

const usePasajerosStore = create((set, get) => ({
  idCompra: "",
  datosPasajeros: [], // Initialize with an empty array
  tjw: "",
  isLoading: false,

  setIdCompra: (newIdCompra) => set({ idCompra: newIdCompra }),
  setTjw: (newTjw) => set({ tjw: newTjw }),
  setDatosPasajeros: (newDatosPasajeros) => set({ datosPasajeros: newDatosPasajeros }),
  getDatosPasajeros: () => get().datosPasajeros,
  getIdCompra: () => get().idCompra,

  initializeStore: async () => {
    const currentTjw = get().tjw;
    const isLoading = get().isLoading;

    if (currentTjw || isLoading) {
      return;
    }
    set({ isLoading: true });

    try {
      const recaptchaToken = await getRecaptchaToken();
      const datos = {
        token: recaptchaToken
      };
      const response = await axios.post(
        `${process.env.REACT_APP_URI_SERVER_LOCAL}/api/Autenticacion/Login/LoginAnon/`,
        datos,
        {
          withCredentials: true,
          headers: {
            'Recaptcha-Token': recaptchaToken,  
          },
        }
      );



      // Hacer una solicitud al backend para recuperar los datos utilizando la cookie
      //const response = await axios.get("https://localhost:5001/api/Auth/token", { withCredentials: true });
      const token = response.data.token;

      if (token) {
        set({ tjw: token });
        // Suponiendo que hay un endpoint para obtener los datos de pasajeros basado en el token
        //const datosResponse = await axios.get("https://localhost:5001/api/Pasajeros", {
        //  headers: {
        //    Authorization: `Bearer ${token}`,
        //  },
        //});

        //set({ datosPasajeros: datosResponse.data });
      }
    } catch (error) {
      console.error("Error initializing store:", error);
    } finally {
      // Marcar la carga como completada
      set({ isLoading: false });
    }
  },
}));

export default usePasajerosStore;
