import React, { useState, useEffect, useCallback } from "react";
import "../Styles/SeccionRenata.css";
import { Button, Form, Table } from "react-bootstrap";
import Planes from "./Planes";
import Comparator from "./Comparator";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CustomSpinner from "./CustomSpinner";
import BootstrapSelect from "react-bootstrap-select-dropdown";
import { useSelector, useDispatch } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import ReactTooltip from 'react-tooltip'
import $ from 'jquery';
import Container from "react-bootstrap/Container";
import ApiUF from './ApiUF'
import {registerLocale} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import es from 'date-fns/locale/es';
import swal from 'sweetalert';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

registerLocale('es', es)

function ComparatorMessage({ data }) {    
  const filter=data.filter(o => o.esDestacado===true);
  if (filter.length>0) {  return <em>**Productos destacados en base a las preferencias de contratación en seguros de coberturas similares</em> ; }    
  return "";    
}
function ValorUF(id){
  if (id===31) return "1.000";
  if (id===32) return "1.100";
  if (id===28) return "1.200";
  return "1.300";
}
function ObtenerSeleccionado(insuranceTypes){
  if (localStorage.getItem("tipoSeguro") !== undefined && localStorage.getItem("tipoSeguro") !== null && insuranceTypes.data !== null)
  {     
    insuranceTypes.data.find(x=>x.labelKey=== +localStorage.getItem("tipoSeguro")).isSelected=true;
  }
  return localStorage.getItem("tipoSeguro") !== undefined && localStorage.getItem("tipoSeguro") !== null ? +localStorage.getItem("tipoSeguro"):null;
}
function SeccionRenata(props) {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const dispatch = useDispatch();
  const infoPrevious = JSON.parse(localStorage.getItem("datosForm"));
  const insuranceTypes = useSelector((state) => state.InsuranceTypes);
  const insuranceByTypeInsurance = useSelector(
    (state) => state.InsuranceByTypeInsurance
  );  
  const insuranceComparator = useSelector((state) => state.InsuranceComparator);
  const [numPeople, setNumPeople] = useState(infoPrevious !== undefined && infoPrevious != null ? infoPrevious.numPersonas!==undefined && infoPrevious.numPersonas != null ? infoPrevious.numPersonas:1: 1);
  const [maxAge, setMaxAge] = useState(infoPrevious !== undefined && infoPrevious != null? infoPrevious.edadMaxima!==undefined && infoPrevious.edadMaxima != null?infoPrevious.edadMaxima:18: 18);
  const [insuranceTypesSelect, setInsuranceTypesSelect] = useState(ObtenerSeleccionado(insuranceTypes));
  const [selectedPlans, setSelectedPlans] = useState([]);
  const [amounts, setAmounts] = useState([5000, 300000]);
  const [showPlans, setShowPlans] = useState(true);
  const [watchAll, setWatchAll] = useState(false);
  const [startDate, setStartDate] = useState(infoPrevious !== undefined && infoPrevious != null? infoPrevious.minDate!==undefined && infoPrevious.minDate != null?new Date(infoPrevious.minDate):null: null);
  const [endDate, setEndDate] = useState(infoPrevious !== undefined && infoPrevious != null? infoPrevious.maxDate!==undefined && infoPrevious.maxDate != null?new Date(infoPrevious.maxDate):null: null);
  const isEndDateDisabled = !startDate;
  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() + 1);
  const [nowDate,setNowDate] = useState(new Date(Date.now() + (3600 * 1000 * 24)));
  //nowDate.setDate(nowDate.getDate() + 1);
  const onChangeNumPeopleChange = (value) => {
    setNumPeople(value);
  };

  const handleChangesetStartDate = (event) => {
    let iden=event.target.value;
    setStartDate(iden);
  };

  const handleChangesetEndDate = (event) => {
    let iden=event.target.value;
    setEndDate(iden);
  };

  const consultarPlanes = async () => {
    const token = await executeRecaptcha('consultarPlanes');
    dispatch({
      type: "INSURANCE_BYTYPE_INSURANCE_REQUEST",
      params: {
        numPersonas: numPeople,
        edadMaxima: maxAge,
        fechaInicio: startDate,
        fechaFin: endDate,
        strTipoSeguro: insuranceTypesSelect.join(),
        sitio:6,
        recaptchaToken: token                              
      },
    });
  }

  const onChangeStartDate = (value) => {
    setStartDate(value);
  };
  
  const onChangesEndDate = (value) => {
    setEndDate(value);
  };
  
  const onChangeMaxAgeChange = (value) => {
    setMaxAge(value);
  };

  const onChangeInsuranceTypesSelect = (e) => {
    setInsuranceTypesSelect(e.selectedKey);
  };

  const onClickSelectedPlans = (id, value) => {
    let aux = selectedPlans;

    if (value) {
      aux.push(id);
    } else {
      aux.pop(id);
    }
    setSelectedPlans(aux);
  };

  const onChangeWatchAll = () => {
    setWatchAll(!watchAll);
  };

  useEffect(() => {
    localStorage.removeItem("datosForm"); 
    
    dispatch({
      type: "INSURANCE_TYPES_REQUEST",
    });
  }, [dispatch]);

  useEffect(() => {
    setShowPlans(false);
  }, [insuranceComparator.data]);

  useEffect(() => {
    setShowPlans(true);
  }, [insuranceByTypeInsurance.data]);

  useEffect(() => {
    if (infoPrevious !== undefined && infoPrevious != null)
    {
        $('html, body').animate({
          scrollTop: $("#sr").offset().top
        }, 0);
        localStorage.removeItem("datosForm"); 
    } 
  });

  function ObtenerMeses(fechainicio, fechafin) {
    let diferencia = (fechainicio.getTime() - fechafin.getTime())/1000;
        diferencia /= (60 * 60 * 24 * 7 * 4);
        return Math.abs(Math.floor(diferencia)) - 1;
  }

  function VerMasBoton(longitud)
  {
    if (insuranceByTypeInsurance.data.length>4)
    {
      return <Row> 
            <Button
            className="btnvermas"
              variant="outline-primary"
              onClick={onChangeWatchAll}
              style={{fontSize:"1.5rem", fontWeight:"bold", border:"solid 3px" }}
            >
              {!watchAll ? "▼      Ver Más      ▼" : "▲      Ocultar      ▲"}
            </Button>
        </Row>
    }
    else
    {
      return ""
    }
  }
  return (
    <div id="sr">      
      <div>
        <div className="mt-4">
          <br />
          <h1 className="titulos">
          Cotiza tu seguro de viaje
          </h1>
          <h6 className="subtitulos">Realiza tu cotización en simples pasos<br/><br/>
          </h6>        
        </div>
        {!insuranceTypes.fetching && insuranceTypes.data && (
          
          <div className="container srform">
            <Row style={{ marginBottom: 20, flex: 1, alignItems: "flex-end", justifyContent:"center"}}>
              <Col lg={2} className="mb-3 srnpersonas">
                <Row>
                  <Col className="srlbnpersonas">
                  <span data-tip data-for="srtooltip1">
                    <Form.Label 
                      style={{
                        color: "grey",
                        paddingRight: 10,
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      {`¿Para\n cuántas personas? `}

                    </Form.Label>
                      <button type="button" className="btn position-relative">
                        <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-info">
                          i
                        </span>
                      </button>

                    </span>

                    <ReactTooltip id="srtooltip1" type="warning" effect="solid">
                    Máximo 5 personas, incluyendo<br/> titular + adicionales
                   
                    </ReactTooltip>
                  </Col>
                </Row>
                <Row>
                  <Col   className="  srnpersonas">
                    <CustomSpinner
                      type={"int"}
                      max={5}
                      min={1}
                      step={1}
                      onChange={onChangeNumPeopleChange}
                      size="sm"
                      arrows={false}
                      value={numPeople}
                    />
                  </Col>
                </Row>
              </Col>

              <Col lg={2} className="mb-3 srnedad">
                <Row>
                  <Col className="srlbedadmayor">
                  <span data-tip data-for="srtooltip2">
                    <Form.Label 
                      style={{
                        color: "grey",
                        paddingRight: 10,
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      {" Edad del \n mayor del grupo"}
                    </Form.Label>

                    <button type="button" className="btn position-relative">
                      <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-info">
                        i
                      </span>
                    </button>

                  </span>
                  <ReactTooltip id="srtooltip2" type="warning" effect="solid">
                  La edad máxima para contratar este seguro es <br/>de  68 años y 364 días.
                  <br/> Esto aplica también para sus acompañantes.
                  </ReactTooltip>
                  </Col>
                </Row>
                <Row>

                  <Col className="  srnedad" >
                    <CustomSpinner
                      type={"int"}
                      precision={2}
                      max={68}
                      min={18}
                      step={1}
                      onChange={onChangeMaxAgeChange}
                      size="sm"
                      value={maxAge}
                    />
                  </Col>
                </Row>
              </Col>

              <Col lg={2} className="mb-3 srcolida">
              <Row>
                <Col>
              <Form.Label
                    className="srlbidavuelta"
                    >
                      {"Ida"}
                    </Form.Label>
                    </Col>
                    </Row>
                    <Row>
                <Col>
                <DatePicker
                className="fecha"
                selected={startDate}
                onChange={onChangeStartDate}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                minDate={nowDate}
                maxDate={maxDate}
                dateFormat="dd/MM/yyyy"
                placeholderText="dd/mm/aaaa"
                locale="es"
              />
              </Col>
              </Row>
              </Col>

              <Col lg={2} className="mb-3 srcolvuelta">
                <Row>
                  <Col>
              <Form.Label
                    className="srlbidavuelta"
                    >
                      {"Vuelta"}
                    </Form.Label>
                    </Col>
                    </Row>
              <Row>
                <Col>
                <DatePicker
                className="fecha"
                selected={endDate}
                onChange={onChangesEndDate}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                maxDate={maxDate}
                disabled={isEndDateDisabled}
                dateFormat="dd/MM/yyyy"
                placeholderText="dd/mm/aaaa"
                locale="es"
              />
               </Col>
              </Row>
              </Col>
               <Col lg={3} className="mb-3 srcolselect">
                <Row>
                  <Col>
                    <Form.Label
                    className="srlabelselect"
                    >
                      {"Tipo de Destino"}
                    </Form.Label>
                  </Col>
                </Row>
                <Row>
                  <Col className="srselect">
                    <BootstrapSelect
                      options={insuranceTypes.data}
                      onChange={(e) => onChangeInsuranceTypesSelect(e)}
                      placeholder="Seleccione su destino"
                      actionBtnStyle="btn-primary"
                      selectStyle="btn-primary"
                    /> 
                  </Col>
                </Row>
              </Col> 
              <Col lg={2} className="mb-3" style={{marginLeft:"-5rem"}}>
                <Row>
                  <Col>
                    <div className="d-grid gap-2 srbtnverplanes">                    
                      <Button
                        className="srbtncotiza"
                        variant="warning"
                        onClick={() => {
                          if(insuranceTypesSelect.join() === 0){
                            swal({
                              title: "ALERTA",
                              text: "DEBE SELECCIONAR UN TIPO DE DESTINO",
                              icon:"warning",
                              button: "OK"
                            });
                            return "";
                         }
                         if (startDate === null || endDate === null) {
                          swal({
                            title: "ALERTA",
                            text: "DEBE SELECCIONAR UN RANGO DE FECHA IDA Y VUELTA",
                            icon:"warning",
                            button: "OK"
                          });
                          return "";
                         }
                         if (startDate >= endDate) {
                          swal({
                            title: "ALERTA",
                            text: "DEBE SELECCIONAR UN RANGO DE FECHA PERMITIDO",
                            icon:"warning",
                            button: "OK"
                          });
                          return "";
                         }
                          if (ObtenerMeses(new Date (startDate), new Date (endDate)) > 12) {
                           swal({
                             title: "ALERTA",
                             text: "DEBE SELECCIONAR UN RANGO DE FECHA MENOR A 12 MESES",
                             icon:"warning",
                             button: "OK"
                           });
                           return "";
                          }
                          setSelectedPlans([]);
                          consultarPlanes();                                                                                                                               
                        }}
                      >
                        {`Ver Planes`}
                        {insuranceByTypeInsurance.fetching && (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        )}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        )}

        <Row>
          <Col style={{ textAlign: "center" }}>
            {(insuranceByTypeInsurance.fetching ||
              insuranceComparator.fetching) && (
              <React.Fragment>
                <Spinner
                  as="span"
                  animation="border"
                  role="status"
                  aria-hidden="true"
                  variant="primary"
                />
                <p>Cargando...</p>
              </React.Fragment>
            )}
          </Col>   
        </Row>

        {showPlans &&
          !insuranceByTypeInsurance.fetching &&
          insuranceByTypeInsurance.data && (
            <React.Fragment>
              {selectedPlans && (
                <Row className="mb-3 justify-content-center">
                  <Col xs={12} md={12} lg={4}>
                    {/* <div className="d-grid gap-2"  style={{ justifyContent:"center" }}>
                      <Button
                        variant="primary"
                        style={{ color: "white", width:"100%", fontWeight:"bold", backgroundColor:"#f19801"}}
                        onClick={() => {
                          dispatch({
                            type: "INSURANCE_COMPARATOR_REQUEST",
                            params: {
                              numPersonas: numPeople,
                              edadMaxima: maxAge,
                              vmMaximo: amounts[1],
                              vmMinimo: amounts[0],
                              strSeguros: selectedPlans.join(),
                            },
                          });
                        }}
                      >
                        {`Comparar planes `}
                        {insuranceComparator.fetching && (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        )}
                      </Button>
                    </div> */}
                  </Col>
                </Row>
              )}
              <Row style={{justifyContent:"center"}}>
                {insuranceByTypeInsurance.data.map((aux, index) => ( 
                  <Col lg={3} md={6} xs={12}>
                    <Planes
                      key={index}
                      data={aux}
                      index={index}
                      watchAll={watchAll}
                      selectedList={selectedPlans}
                      numPeople={numPeople}
                      amounts={amounts}
                      maxAge={maxAge}
                      minDate={startDate}
                      maxDate={endDate}
                      click={onClickSelectedPlans}
                    />
                  </Col>
                  
                ))}

                </Row>
                <p className="container srapi"><ApiUF/></p> 
              <VerMasBoton longitud={insuranceByTypeInsurance.data.length} />            
            </React.Fragment>
          )}
        {!showPlans &&
          !insuranceComparator.fetching &&
          insuranceComparator.data && (
            <Container>
              <div style={{textAlign:"right"}} onClick={() => {
                          setSelectedPlans([]);
                          consultarPlanes();                          
                        }}>   
                        {/* <h3 className="text-center">Planes</h3>     */}
                          <i data-tip="React-tooltip" data-for="srtooltipVolverPlan" style={{"fontSize" : "20px", color:"gray"}}  className="bi bi-arrow-return-left cpbtncerrar">
                            <span style={{ fontSize:"20px", marginLeft:"0.5rem"}}>Volver</span>
                            {/* <ReactTooltip id="srtooltipVolverPlan" place="right" type="warning" effect="solid">
                              Volver
                            </ReactTooltip> */}
                          </i></div>
              <Comparator data={insuranceComparator.data} />
              <div style={{marginTop:"-1rem"}}>
              <p style={{fontSize:"0.8rem"}}>
                  {/* <em>*Aplican condiciones</em><br /> */}
                  <Table bordered={true}>
                <tbody className="cbody" style={{textAlign:"center", fontSize:"14px"}}>
                    <tr style={{backgroundColor:"#EE811E", color:"white", fontWeight:"bold",fontSize:"15px"}}>
                        <th>Coberturas</th>
                    </tr>
                    <tr style={{backgroundColor:"#F9E0CA"}}>
                        <td>Cobertura de Muerte Accidental (solo para el titular) UF {ValorUF(insuranceComparator.data[0].idSeguro)}</td>
                    </tr>
                    <tr style={{backgroundColor:"#FBEFE3"}}>
                        <td>Cobertura de reembolso de gastos médicos por accidente (solo para adicionales) UF 15</td>
                    </tr>
                </tbody>
            </Table>
            <div style={{textAlign:"right", marginTop:"-0.8rem"}} onClick={() => {
                          setSelectedPlans([]);
                          document.getElementById('sr').scrollIntoView({ behavior: 'smooth' });
                          consultarPlanes();
                        }}>   
                        {/* <h3 className="text-center">Planes</h3>     */}
                          <i data-tip="React-tooltip" data-for="srtooltipVolverPlan" style={{"fontSize" : "20px", color:"gray"}}  className="bi bi-arrow-return-left cpbtncerrar">
                            <span style={{ fontSize:"20px", marginLeft:"0.5rem"}}>Volver</span>
                            {/* <ReactTooltip id="srtooltipVolverPlan" place="right" type="warning" effect="solid">
                              Volver
                            </ReactTooltip> */}
                          </i></div>
                  <ComparatorMessage data={insuranceComparator.data} />
              </p>   
                       
            </div>

            </Container>         
          )}          
      </div>
    </div>
  );
}
export default SeccionRenata;
